import React from 'react';
import authRoles from '../../../auth/authRoles';
import Payout from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/Payout';

const PayoutAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/payout',
			element: <Payout />,
		},
	],
};

export default PayoutAppConfig;
