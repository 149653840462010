import { useState } from 'react';
import moment from 'moment-timezone';
import Typography from '@mui/material/Typography';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';
import { Icon, Button, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import settlementOrderTypes from '@ameroservices-platform/shared/enums/settlementOrderTypes';
import SaleOrderLineDetails from '@ameroservices-platform/loppe-boothownerui/app/main/apps/sale/SaleOrderLineDetails';
import { useFlags } from 'launchdarkly-react-client-sdk';
import clsx from 'clsx';
import Hidden from '@mui/material/Hidden';
import { selectCustomerProductGroups } from '@ameroservices-platform/loppe-boothownerui/app/main/apps/globalStore';
import { useSelector } from 'react-redux';

function SaleOrderLine({ orderLine, settlementOrderLine, index }) {
	const productGroups = useSelector(selectCustomerProductGroups);
	const flags = useFlags();
	const navigate = useNavigate();
	const [open, setOpen] = useState(null);
	const [expanded, setExpanded] = useState(false);

	const handleClickOnProductDetails = (orderLine, event) => {
		if (flags.showExtendedDetailsForSalesOverviewOnLoppeBoothOwnerUi) {
			event.stopPropagation();
		}
		if (orderLine?.ameroServicesProductNumber) {
			navigate('/sale/' + encodeURIComponent(orderLine.ameroServicesProductNumber), {
				state: {
					productName: orderLine?.name,
					productNumber: orderLine?.ameroServicesProductNumber
				}
			});
		}
	};

	return (
		<>
			<TableRow
				className={clsx(
					'hidden md:table-row',
					flags.showExtendedDetailsForSalesOverviewOnLoppeBoothOwnerUi && 'cursor-pointer'
				)}
				onClick={() => {
					if (flags.showExtendedDetailsForSalesOverviewOnLoppeBoothOwnerUi) {
						setExpanded(oldState => !oldState);
					}
				}}
			>
				<TableCell>
					<Button variant={'outlined'} onClick={event => handleClickOnProductDetails(orderLine, event)}>
						Detaljer
					</Button>
				</TableCell>
				<TableCell>
					<Typography className="truncate">{orderLine.name}</Typography>
				</TableCell>
				{flags.showBoothAtSaleOnBoothOwnerUi && (
					<TableCell>
						<Typography className="truncate">
							{productGroups?.find(productGroup => productGroup.id === orderLine.productGroupUid)?.name ??
								'-'}
						</Typography>
					</TableCell>
				)}
				<TableCell>
					<Typography>{orderLine.qty}</Typography>
				</TableCell>
				<TableCell>
					<Typography>{moneyFormatter.format(orderLine.linePrice / 100.0)} DKK</Typography>
				</TableCell>
				<TableCell>
					<Typography>{moment(orderLine.date).format('DD-MM-YYYY HH:mm')}</Typography>
				</TableCell>
				{flags.showExtendedDetailsForSalesOverviewOnLoppeBoothOwnerUi && (
					<TableCell>
						{settlementOrderLine &&
							settlementOrderLine?.settlementOrderType !== settlementOrderTypes.PAYOUT_BANK && (
								<Icon fontSize={'medium'} className={expanded ? '' : 'rotate-180'}>
									arrow_drop_up
								</Icon>
							)}
					</TableCell>
				)}
			</TableRow>

			<div className="table-row md:hidden">
				<div
					className="flex items-center justify-between my-20 cursor-pointer"
					onClick={() => setOpen(open === index ? -1 : index)}
				>
					<Typography className="truncate text-md font-bold">{orderLine.name}</Typography>

					<Icon fontSize={'large'} className={open === index ? 'rotate-180' : ''}>
						{open === index ? 'arrow_drop_up' : 'arrow_drop_up'}
					</Icon>
				</div>
				<div
					className={`${
						open === index ? 'block grid grid-cols-2 gap-y-12 items-center mb-20' : 'hidden'
					} mt-2`}
				>
					<Typography>
						<span className="font-bold">Antal: </span>
						{orderLine.qty}
					</Typography>
					<Typography>{moment(orderLine.date).format('DD-MM-YYYY HH:mm')}</Typography>
					<Typography>
						<span className="font-bold">Pris: </span>
						{moneyFormatter.format(orderLine.linePrice / 100.0)}
					</Typography>
					{flags.showBoothAtSaleOnBoothOwnerUi && (
						<Typography>
							<span className="font-bold">Stand: </span>
							{productGroups?.find(productGroup => productGroup.id === orderLine.productGroupUid)?.name ??
								'-'}
						</Typography>
					)}

					<Button variant={'outlined'} onClick={() => handleClickOnProductDetails(orderLine)}>
						Detaljer
					</Button>
				</div>
			</div>

			{flags.showExtendedDetailsForSalesOverviewOnLoppeBoothOwnerUi && (
				<>
					<Hidden mdUp>
						{open === index && settlementOrderLine && (
							<SaleOrderLineDetails orderLine={orderLine} settlementOrderLine={settlementOrderLine} />
						)}
					</Hidden>
					<Hidden mdDown>
						{expanded && settlementOrderLine && (
							<SaleOrderLineDetails orderLine={orderLine} settlementOrderLine={settlementOrderLine} />
						)}
					</Hidden>
				</>
			)}
		</>
	);
}

export default SaleOrderLine;
