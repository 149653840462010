import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	productGroupsByCustomerListener,
	selectCustomer,
} from '@ameroservices-platform/loppe-boothownerui/app/main/apps/globalStore';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { useLocation } from 'react-router-dom';

function StoreInit({ children }) {
	const selectedCustomer = useSelector(selectCustomer);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (selectedCustomer) {
			const productGroupsListenerUnSub = dispatch(productGroupsByCustomerListener(selectedCustomer.id));

			return () => {
				productGroupsListenerUnSub();
			};
		}
	}, [selectedCustomer]);

	return children;
}

export default StoreInit;
