import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import appsConfigs from '@ameroservices-platform/loppe-boothownerui/app/main/apps/appsConfigs';
import LoginConfig from '@ameroservices-platform/loppe-boothownerui/app/main/login/LoginConfig';
import LogoutConfig from '@ameroservices-platform/loppe-boothownerui/app/main/logout/LogoutConfig';
import ForgotPasswordConfig from '@ameroservices-platform/loppe-boothownerui/app/main/forgot-password/ForgotPasswordConfig';
import ResetPasswordPageConfig from '@ameroservices-platform/loppe-boothownerui/app/main/reset-password/ResetPasswordPageConfig';
import ActivateAccountPageConfig from '@ameroservices-platform/loppe-boothownerui/app/main/activate-account/ActivateAccountPageConfig';
import FuseLoading from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import AuthRedirect from '../auth/AuthRedirect';

const routeConfigs = [
	...appsConfigs,
	LogoutConfig,
	ForgotPasswordConfig,
	ResetPasswordPageConfig,
	ActivateAccountPageConfig,
	LoginConfig
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		element: <AuthRedirect />
	},
	{
		path: 'loading',
		element: <FuseLoading />
	},
	{
		path: '*',
		element: <Navigate to="error/404" />
	}
];

export default routes;
