export const GraphLocaleConfig = [
	{
		name: 'da',
		options: {
			months: [
				'Januar',
				'Februar',
				'Marts',
				'April',
				'Maj',
				'Juni',
				'Juli',
				'August',
				'September',
				'Oktober',
				'November',
				'December',
			],
			shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
			days: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
			shortDays: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
		},
	},
];
