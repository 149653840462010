import { authRoles } from '@ameroservices-platform/loppe-boothownerui/app/auth';
import ActivateAccountPage from './ActivateAccountPage';

const ActivateAccountPageConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	routes: [
		{
			path: 'activate-account',
			element: <ActivateAccountPage />,
		},
	],
};

export default ActivateAccountPageConfig;
