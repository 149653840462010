import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';
const BoothDetails = lazy(() => import('@ameroservices-platform/loppe-boothownerui/app/main/apps/booth/BoothDetails'));
const Booths = lazy(() => import('@ameroservices-platform/loppe-boothownerui/app/main/apps/booth/Booths'));

const BoothAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/booths',
			element: <Booths />,
		},
		{
			path: '/booths/:boothId',
			element: <BoothDetails />,
		},
	],
};

export default BoothAppConfig;
