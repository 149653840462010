import React, { useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useDispatch, useSelector } from 'react-redux';
import {
	customerOrderLineListener,
	customerOrderListener,
	selectCustomerOrder,
	selectCustomerOrderLine,
} from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/store/customerSlice';
import FuseLoading from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseLoading';
import BoothOwnerAccountSettlementOrderLineSaleTableRow from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/BoothOwnerAccountSettlementOrderLineSaleTableRow';

function BoothOwnerAccountSettlementOrderLineSaleTable({ settlementOrderLine }) {
	const dispatch = useDispatch();

	const _settlementOrderLine = useSelector((state) => selectCustomerOrderLine(state, settlementOrderLine.id));
	const settlementOrder = useSelector((state) => selectCustomerOrder(state, settlementOrderLine.orderUid));

	useEffect(() => {
		const unsubFuncOrderLine = dispatch(
			customerOrderLineListener(
				settlementOrderLine.customerUid,
				settlementOrderLine.orderUid,
				settlementOrderLine.id
			)
		);
		const unsubFuncOrder = dispatch(
			customerOrderListener(settlementOrderLine.customerUid, settlementOrderLine.orderUid)
		);
		return () => {
			unsubFuncOrderLine();
			unsubFuncOrder();
		};
	}, [dispatch, settlementOrderLine]);

	const settlementOrderLineOrderLines = useMemo(() => {
		if (_settlementOrderLine) {
			return _settlementOrderLine.orderLines;
		}
		return null;
	}, [dispatch, _settlementOrderLine]);

	return (
		<>
			{settlementOrderLineOrderLines && settlementOrder && (
				<Table size="small" aria-label="settlementOrderLineOrderLines">
					<TableBody>
						{Object.entries(settlementOrderLineOrderLines)
							.sort(([aKey, aValue], [bKey, bValue]) => {
								if (
									aValue.calculations &&
									aValue.calculations.calculationOrder &&
									bValue.calculations &&
									bValue.calculations.calculationOrder
								) {
									return aValue.calculations.calculationOrder - bValue.calculations.calculationOrder;
								}
								return 0;
							})
							.map(([loppeOrderLineUid, settlementOrderLineOrderLine]) => (
								<BoothOwnerAccountSettlementOrderLineSaleTableRow
									key={loppeOrderLineUid}
									settlementOrder={settlementOrder}
									loppeOrderLineUid={loppeOrderLineUid}
									settlementOrderLineOrderLine={settlementOrderLineOrderLine}
								/>
							))}
					</TableBody>
				</Table>
			)}
			{!(settlementOrderLineOrderLines && settlementOrder) && <FuseLoading />}
		</>
	);
}

export default BoothOwnerAccountSettlementOrderLineSaleTable;
