import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import productGroupState from '@ameroservices-platform/shared/enums/productGroupState';
import { updateNavigationItem as updateNavigationItemFuse } from '@ameroservices-platform/loppe-boothownerui/app/store/fuse/navigationSlice';

const globalSlice = createSlice({
	name: 'global',
	initialState: {
		customer: null,
		booths: [],
		subscriptions: [],
		orders: [],
		ticketTypes: [],
		productGroups: [],
		products: {},
		payout: null,
		settlementOrderLinesBigQuery: null,
		orderLinesBigQuery: null,
		orderLinesBigQueryMaxResults: null,
		orderLinesBigQueryTimestamp: null,
		orderLinesBigQueryOffset: 0,
		customerSettlementAccountAmount: null,
		salesKeyFigures: null,
		graph: {
			detail: { data: [], labels: [] },
			month: { data: [], labels: [] },
			year: { data: [], labels: [] },
		},
	},
	reducers: {
		setBooths: (state, action) => {
			state.booths = action.payload;
		},
		setSubscriptions: (state, action) => {
			state.subscriptions = action.payload;
		},
		setOrders: (state, action) => {
			state.orders = action.payload;
		},
		setTicketTypes: (state, action) => {
			state.ticketTypes = action.payload;
		},
		setProducts: (state, action) => {
			state.products = action.payload;
		},
		setProductGroups(state, action) {
			state.productGroups = action.payload;
		},
		setPayout: (state, action) => {
			state.payout = action.payload;
		},
		setCustomer: (state, action) => {
			state.customer = action.payload;
		},
		setSettlementOrderLinesBigQuery(state, action) {
			state.settlementOrderLinesBigQuery = action.payload;
		},
		setOrderLinesBigQuery(state, action) {
			state.orderLinesBigQuery = action.payload;
		},
		setOrderLinesBigQueryMaxResults(state, action) {
			state.orderLinesBigQueryMaxResults = action.payload;
		},
		setOrderLinesBigQueryTimestamp(state, action) {
			state.orderLinesBigQueryTimestamp = action.payload;
		},
		setOrderLinesBigQueryOffset(state, action) {
			state.orderLinesBigQueryOffset = action.payload;
		},
		setGraphData(state, action) {
			state.graph[action.payload.type] = action.payload.data;
		},
		copyGraphMonthDataForDetailData(state) {
			state.graph.detail = state.graph.month;
		},
		setCustomerSettlementAccountAmount(state, action) {
			state.customerSettlementAccountAmount = action.payload;
		},
		setSalesKeyFigures(state, action) {
			state.salesKeyFigures = action.payload;
		},
	},
});

export const {
	setPayout,
	setBooths,
	setCustomer,
	setSubscriptions,
	setOrders,
	setTicketTypes,
	setProductGroups,
	setProducts,
	setSettlementOrderLinesBigQuery,
	setOrderLinesBigQuery,
	setOrderLinesBigQueryMaxResults,
	setOrderLinesBigQueryTimestamp,
	setOrderLinesBigQueryOffset,
	copyGraphMonthDataForDetailData,
	setGraphData,
	setCustomerSettlementAccountAmount,
	setSalesKeyFigures,
} = globalSlice.actions;

export const getSalesKeyFiguresFromBigQuery = (productNumber, timestamp) => (dispatch) => {
	return firebaseService
		.callFunctionByName('organisationCustomerOrderOrderlineGetSalesKeyFiguresFromBigQuery', {
			...(productNumber && { productNumber }),
			timestamp,
		})
		.then((response) => {
			if (response.data.salesKeyFigures) {
				return dispatch(setSalesKeyFigures(response.data.salesKeyFigures));
			}
		});
};

export const getCustomerOrderLinesFromBigQuery = (productNumber, timestamp, offset) => (dispatch) => {
	dispatch(setOrderLinesBigQuery(null));
	return firebaseService
		.callFunctionByName('organisationCustomerOrderOrderlineGetOrderLinesFromBigQuery', {
			...(productNumber && { productNumber }),
			timestamp,
			offset,
		})
		.then((response) => {
			const _customerOrderLines = response.data.lines.map((customerOrderLine) => {
				return {
					...customerOrderLine,
					date: customerOrderLine.date.value,
				};
			});
			dispatch(setOrderLinesBigQuery(_customerOrderLines));
			dispatch(setSettlementOrderLinesBigQuery(response.data.settlementLines));
			dispatch(setOrderLinesBigQueryMaxResults(response.data.resultsSize));
		});
};

export const getGraphDataForType =
	(from, to, type, productNumber = '') =>
	(dispatch) => {
		firebaseService
			.callFunctionByName('bigQueryGetDashboardStats', {
				from,
				to,
				productNumber,
				test: false,
			})
			.then((response) => {
				const data = {
					data: response.data.data,
					labels: response.data.labels,
				};
				dispatch(setGraphData({ data, type }));
			});
	};

export const getCustomerSettlementAccountAmount =
	({ settlementAccountType, createdBeforeDate, doNotUseLatestSettlementAccountAmountSnapshot }) =>
	(dispatch) => {
		return firebaseService
			.callFunctionByName('organisationCustomerSettlementAccountGetSettlementAccountAmount', {
				settlementAccountType,
				createdBeforeDate,
				doNotUseLatestSettlementAccountAmountSnapshot,
			})
			.then((response) => {
				if (response && response.data && response.data.settlementAccountAmount) {
					dispatch(setCustomerSettlementAccountAmount(response.data.settlementAccountAmount));
				} else {
					dispatch(setCustomerSettlementAccountAmount(0));
				}
			});
	};

export const subscriptionsListener = (customerUid) => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('customer_subscriptions')
		.where('deleted', '==', false)
		.onSnapshot((query) => {
			const subscriptions = [];
			query.forEach((doc) => {
				const docData = doc.data();
				subscriptions.push({
					...docData,
					id: doc.id,
				});
			});
			dispatch(setSubscriptions(subscriptions));
		});
};

export const ordersListener = (customerUid) => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.where('deleted', '==', false)
		.onSnapshot((query) => {
			const orders = [];
			query.forEach((doc) => {
				const docData = doc.data();
				orders.push({
					...docData,
					id: doc.id,
					createdAt: docData.createdAt ? docData.createdAt.toDate() : null,
				});
			});
			dispatch(setOrders(orders));
		});
};

export const productGroupsByCustomerListener = (customerUid) => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('ticketTypes')
		.where('customerUid', '==', customerUid)
		.where('deleted', '==', false)
		.onSnapshot(
			(query) => {
				const productGroups = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

				if (productGroups.length > 0) {
					const actives = productGroups.filter(
						(productGroup) =>
							productGroup.state === productGroupState.ACTIVE
					);
					const activeWithProductCreation = actives.filter(
						(productGroup) =>
							productGroup.disableProductCreation !== true
					);
					const reserved = productGroups.filter(
						(productGroup) => productGroup.state === productGroupState.RESERVED
					);
					const inactive = productGroups.filter(
						(productGroup) => productGroup.state === productGroupState.INACTIVE
					);
					const expired = productGroups.filter(
						(productGroup) => productGroup.state === productGroupState.EXPIRED
					);
					dispatch(setProductGroups([...actives, ...reserved, ...inactive, ...expired]));
					if (activeWithProductCreation.length > 0) {
						dispatch(
							updateNavigationItemFuse('createProduct', {
								title: 'Opret vare',
								type: 'collapse',
								icon: 'add',
								exact: true,
								children: activeWithProductCreation.map((productGroup) => ({
									id: 'createProductProductGroup-' + productGroup.id,
									title: productGroup.name,
									type: 'item',
									url: '/booths/' + productGroup.id,
									exact: true,
									state: {
										createProduct: true,
									},
								})),
							})
						);
					} else {
						dispatch(
							updateNavigationItemFuse('createProduct', {
								type: 'divider',
							})
						);
					}
				}
			},
			(error) => {
				console.error('productGroupsByCustomerListener', { customerUid, error });
			}
		);
};

/**
 * @param {string} productUid - product uid
 * @param {number} amount - number of labels to create
 * @returns {Promise<*>} - don't know what message is returned - feel free to adjust
 */
export const createLabels = async (productUid, amount) => {
	try {
		const resp = await firebaseService.callFunctionByName('organisationProductAddProductAmount', {
			productUid,
			amount,
		});
		return resp.data;
	} catch (e) {
		throw new Error(e);
	}
};

export const createSettlementOrder = async (data) => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerOrderCreateSettlementOrder', data);
	if (!resp?.data) {
		return false;
	}
	return resp.data;
};

export const getSubscriptionTypeCurrentEvent = async (
	subscriptionTypeUid,
	currentEvent = null,
	eventGroupUid = null
) => {
	const result = await firebaseService.callFunctionByName(
		'organisationSubscriptionTypeGetSubscriptionTypeCurrentEvent',
		{
			subscriptionTypeUid,
			currentEvent,
			eventGroupUid,
		}
	);
	return result.data;
};

export default globalSlice.reducer;

export const selectPayout = (state) => state.global.payout;
export const selectCustomer = (state) => state.global.customer;
export const selectBooths = (state) => state.global.booths;
export const selectSubscriptions = (state) => state.global.subscriptions;
export const selectOrders = (state) => state.global.orders;
export const selectTicketTypes = (state) => state.global.ticketTypes;
export const selectProducts = (state) => state.global.products;
export const selectSettlementOrderLinesBigQuery = (state) => state.global.settlementOrderLinesBigQuery;
export const selectOrderLinesBigQuery = (state) => state.global.orderLinesBigQuery;
export const selectSalesKeyFigures = (state) => state.global.salesKeyFigures;
export const selectOrderLinesBigQueryMaxResults = (state) => state.global.orderLinesBigQueryMaxResults;
export const selectOrderLinesBigQueryTimestamp = (state) => state.global.orderLinesBigQueryTimestamp;
export const selectOrderLinesBigQueryOffset = (state) => state.global.orderLinesBigQueryOffset;
export const selectGraph = (state, type) => state.global.graph[type];
export const selectCustomerSettlementAccountAmount = (state) => state.global.customerSettlementAccountAmount;
export const selectCustomerProductGroups = (state) => state.global.productGroups;
