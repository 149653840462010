import React from 'react';
import authRoles from '../../../auth/authRoles';
import Sale from '@ameroservices-platform/loppe-boothownerui/app/main/apps/sale/Sale';
import ProductSale from '@ameroservices-platform/loppe-boothownerui/app/main/apps/sale/ProductSale';

const SaleAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/sale',
			element: <Sale />,
		},
		{
			path: '/sale/:productuid',
			element: <ProductSale />,
		},
	],
};

export default SaleAppConfig;
