import * as React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Icon,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import ApexLineChart from '@ameroservices-platform/shared/ui-components/graphs/ApexLineChart';
import { getGraphOptions } from '@ameroservices-platform/loppe-boothownerui/app/main/apps/sale/graphOptions';
import Typography from '@mui/material/Typography';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';
import moment from 'moment-timezone';
import {
	getCustomerOrderLinesFromBigQuery,
	getGraphDataForType,
	selectGraph,
	selectOrderLinesBigQuery,
	selectOrderLinesBigQueryMaxResults,
	selectOrderLinesBigQueryOffset,
	selectOrderLinesBigQueryTimestamp,
	setOrderLinesBigQueryOffset,
	setOrderLinesBigQueryTimestamp,
} from '@ameroservices-platform/loppe-boothownerui/app/main/apps/globalStore';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import FuseLoading from '@ameroservices-platform/shared/ui-components/FuseLoading';

const ProductSale = () => {
	const routeParams = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const orderLines = useSelector(selectOrderLinesBigQuery);
	const orderLinesTimestamp = useSelector(selectOrderLinesBigQueryTimestamp);
	const orderLinesMaxResults = useSelector(selectOrderLinesBigQueryMaxResults);
	const orderLinesOffset = useSelector(selectOrderLinesBigQueryOffset);
	const graphDataMonth = useSelector((state) => selectGraph(state, 'month'));
	const theme = useTheme();
	const [open, setOpen] = React.useState(null);

	React.useEffect(() => {
		if (routeParams) {
			const today = new Date();
			const daysAgo = moment(today).subtract(30, 'days').toDate();
			const productNumber = decodeURIComponent(routeParams.productuid);
			dispatch(getGraphDataForType(daysAgo.getTime(), today.getTime(), 'detail', productNumber));
			dispatch(setOrderLinesBigQueryTimestamp(today.getTime()));
			dispatch(setOrderLinesBigQueryOffset(0));
		}
	}, [dispatch, routeParams]);

	React.useEffect(() => {
		if (routeParams) {
			const productNumber = decodeURIComponent(routeParams.productuid);
			dispatch(getCustomerOrderLinesFromBigQuery(productNumber, orderLinesTimestamp, orderLinesOffset));
		}
	}, [dispatch, routeParams, orderLinesTimestamp, orderLinesOffset]);

	const handleClickOnGoBack = () => {
		navigate(-1);
	};

	function handlePageChange(event, newPage) {
		dispatch(setOrderLinesBigQueryOffset(newPage * 10 - 10));
	}

	return (
		<div className="px-16 pt-40">
			<div className="max-w-2xl md:mx-auto md:w-full mx-8">
				<Typography
					className="normal-case flex items-center sm:mb-12 mt-12 sm:mt-0"
					role="button"
					onClick={handleClickOnGoBack}
					color="inherit"
				>
					<Icon className="text-20">arrow_back</Icon>
					<span className="mx-4">Gå tilbage til salgsoversigt</span>
				</Typography>
			</div>
			<Card className="max-w-2xl md:mx-auto md:w-full mx-8 sm:p-20 mb-20 md:mb-40 max-h-780">
				<CardHeader
					title={location?.state?.productName || ''}
					className="bg-gray-200 sm:-mt-20 sm:-mx-20 px-36"
				/>
				<CardContent>
					<div className="mt-24">
						<ApexLineChart
							title={'Salg - seneste 30 dage'}
							seriesWithNameAndData={[{ data: graphDataMonth.data }]}
							height={200}
							options={getGraphOptions(graphDataMonth.labels, theme, 'DD-MM-YYYY')}
						/>
					</div>
					<div className="pt-24 select-none px-4 h-420">
						<Typography variant="h5">Detaljeret salg</Typography>
						<Table>
							<TableHead className="hidden sm:table-header-group">
								<TableRow>
									<TableCell>Produkt navn</TableCell>
									<TableCell>Stk.</TableCell>
									<TableCell>Pris</TableCell>
									<TableCell>Dato</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{orderLines &&
									orderLines.map((orderLine, index) => (
										<>
											<TableRow className="hidden sm:table-row" key={orderLine.id}>
												<TableCell>
													<Typography className="truncate">{orderLine.name}</Typography>
												</TableCell>
												<TableCell>
													<Typography>{orderLine.qty}</Typography>
												</TableCell>
												<TableCell>
													<Typography>
														{moneyFormatter.format(orderLine.linePrice / 100.0)}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography>
														{moment(orderLine.date).format('DD-MM-YYYY HH:mm')}{' '}
													</Typography>
												</TableCell>
											</TableRow>
											<TableRow className="table-row sm:hidden">
												<TableCell>
													<div
														className="flex items-center justify-between my-20 cursor-pointer"
														onClick={() => setOpen(open === index ? -1 : index)}
													>
														<Typography className="truncate text-md font-bold">
															{orderLine.name}
														</Typography>

														<Icon
															fontSize={'large'}
															className={open === index ? 'rotate-180' : ''}
														>
															{open === index ? 'arrow_drop_up' : 'arrow_drop_up'}
														</Icon>
													</div>
													<div
														className={`${
															open === index
																? 'block grid grid-cols-2 gap-y-12 items-center mb-20'
																: 'hidden'
														} mt-2`}
													>
														<Typography>
															<span className="font-bold">Antal: </span>
															{orderLine.qty}
														</Typography>
														<Typography>
															<span className="font-bold">Pris: </span>
															{moneyFormatter.format(orderLine.linePrice / 100.0)}
														</Typography>
														<Typography>
															{moment(orderLine.date).format('DD-MM-YYYY HH:mm')}
														</Typography>
														<Typography>
															<span className="font-bold">Kommission: </span>
															{orderLine.commissionRate / 100}%
														</Typography>
													</div>
												</TableCell>
											</TableRow>
										</>
									))}
							</TableBody>
						</Table>
						{!orderLines && <FuseLoading />}
						{orderLinesMaxResults / 10 >= 2 && (
							<div className={'flex justify-end mt-20'}>
								<Pagination
									count={orderLinesMaxResults / 10}
									shape="rounded"
									onChange={handlePageChange}
								/>
							</div>
						)}
					</div>
				</CardContent>
			</Card>
		</div>
	);
};

export default ProductSale;
