import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	customerOrderLineListener,
	customerProductListener,
	selectCustomerOrderLine,
	selectCustomerProduct,
} from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/store/customerSlice';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';

function BoothOwnerAccountSettlementOrderLineSaleTableRow({
	settlementOrder,
	loppeOrderLineUid,
	settlementOrderLineOrderLine,
}) {
	const dispatch = useDispatch();

	const product = useSelector((state) => selectCustomerProduct(state, settlementOrderLineOrderLine.productUid));
	const loppeOrderLine = useSelector((state) => selectCustomerOrderLine(state, loppeOrderLineUid));

	useEffect(() => {
		const unsubFuncProduct = dispatch(customerProductListener(settlementOrderLineOrderLine.productUid));
		const unsubFuncOrderLine = dispatch(
			customerOrderLineListener(
				settlementOrder.loppeCustomerUid,
				settlementOrder.loppeOrderUid,
				loppeOrderLineUid
			)
		);
		return () => {
			unsubFuncProduct();
			unsubFuncOrderLine();
		};
	}, [dispatch, settlementOrderLineOrderLine]);

	return (
		<>
			{product && loppeOrderLine && (
				<div className="h-160 sm:h-80 my-12">
					<div className="flex flex-col sm:flex-row justify-between" key={settlementOrderLineOrderLine.id}>
						<div>
							<div className="font-bold"># {product.productNumber}</div>
							<div className="truncate">{product.name}</div>
							<div className="truncate">{loppeOrderLine.qty} stk</div>
							<div className="truncate">
								Stk pris: {moneyFormatter.format(loppeOrderLine.unitPrice / 100.0)}
							</div>
						</div>
						<div>
							<div className="truncate ">
								Rabat:{' '}
								{settlementOrderLineOrderLine.discount
									? moneyFormatter.format(settlementOrderLineOrderLine.discount / 100.0)
									: '-'}
							</div>
							<div className="truncate">
								Total: {moneyFormatter.format(settlementOrderLineOrderLine.linePrice / 100.0)}
							</div>
							<div className="truncate">
								Kommission: {`${settlementOrderLineOrderLine.commissionRate / 100} %`}
							</div>
							<div className="truncate ">
								Standholder andel:{' '}
								{moneyFormatter.format(settlementOrderLineOrderLine.calculations.accountShare / 100.0)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default BoothOwnerAccountSettlementOrderLineSaleTableRow;
