import moment from 'moment';
import { GraphLocaleConfig } from '@ameroservices-platform/shared/ui-components/graphs/GraphLocaleConfig';

export const getGraphOptions = (labels, theme, dateFormat) => {
	return {
		chart: {
			defaultLocale: 'da',
			locales: [...GraphLocaleConfig],
			zoom: {
				enabled: false,
			},
			animations: {
				enabled: false,
			},
			type: 'line',
			dropShadow: {
				enabled: true,
				color: '#000',
				top: 18,
				left: 7,
				blur: 10,
				opacity: 0.2,
			},
			toolbar: {
				show: false,
			},
		},
		colors: [theme.palette.text.secondary],
		stroke: {
			curve: 'straight',
			width: 3,
		},
		grid: {
			borderColor: '#e7e7e7',
		},
		markers: {
			size: 4,
		},
		xaxis: {
			categories: labels,
			tooltip: {
				enabled: false,
			},
			type: 'datetime',
		},
		tooltip: {
			enabled: true,
			x: {
				show: true,
				formatter: (val) => {
					return moment(val).format(dateFormat || 'DD-MM-YYYY');
				},
			},
			y: [
				{
					title: {
						formatter: () => {
							return 'Omsætning:';
						},
					},
				},
			],
		},
	};
};
