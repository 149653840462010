const correctionSettlementOrderReasonTypes = {
	NEGATIVE_AMOUNT_BOOTH_OWNER_ACCOUNT: 'negativeAmountBoothOwnerAccount',
	MANUAL: 'manual',
};

export const correctionSettlementOrderReasonTypesTranslate = {
	[correctionSettlementOrderReasonTypes.NEGATIVE_AMOUNT_BOOTH_OWNER_ACCOUNT]: 'Negativt beløb på standholder konto',
	[correctionSettlementOrderReasonTypes.MANUAL]: 'Manuel korrektion',
};

export default correctionSettlementOrderReasonTypes;
