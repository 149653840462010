import React from 'react';
import { useLocation } from 'react-router-dom';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { selectSettingsLoaded, setSettings } from './store/shared/frontendSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FuseLoading from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseLoading';

function checkAppleIcon(size) {
	let appleIconElement = document.querySelector('#apple-icon-' + size);
	// <link
	// 	rel="apple-touch-icon"
	// 	type="image/png"
	// 	sizes="72x72"
	// 	href="/siteConfig?type=logo&organisationUid=prjstQAQp3XEMLoAm9sb&image=72x72.png"
	// />;
	if (appleIconElement) {
		appleIconElement.setAttribute(
			'href',
			'/siteConfig?type=logo&organisationUid=' +
				firebaseService.getOrganisationId() +
				'&image=' +
				size +
				'x' +
				size +
				'.png'
		);
	} else {
		appleIconElement = document.createElement('link');
		appleIconElement.setAttribute(
			'href',
			'/siteConfig?type=logo&organisationUid=' +
				firebaseService.getOrganisationId() +
				'&image=' +
				size +
				'x' +
				size +
				'.png'
		);
		appleIconElement.rel = 'apple-touch-icon';
		appleIconElement.type = 'image/png';
		appleIconElement.sizes = size + 'x' + size;
		appleIconElement.id = 'apple-icon-' + size;
		document.getElementsByTagName('head')[0].appendChild(appleIconElement);
	}
}

export default function OrganisationChecker({ children }) {
	const flags = useFlags();
	const location = useLocation();
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (!firebaseService.getOrganisationId()) {
			return;
		}
		fetch('/siteConfig?organisationUid=' + firebaseService.getOrganisationId())
			.then(response => response.json())
			.then(async response => {
				dispatch(setSettings(response));
				if (flags.loppeOptionToUseCustomLogos) {
					let manifestElement = document.querySelector('#manifest');
					if (manifestElement) {
						manifestElement.setAttribute(
							'href',
							'/siteConfig?type=manifest&organisationUid=' + firebaseService.getOrganisationId()
						);
					} else {
						manifestElement = document.createElement('link');
						manifestElement.href =
							'/siteConfig?type=manifest&organisationUid=' + firebaseService.getOrganisationId();
						manifestElement.rel = 'manifest';
						manifestElement.id = 'manifest';
						document.getElementsByTagName('head')[0].appendChild(manifestElement);
					}

					checkAppleIcon(72);
					checkAppleIcon(114);
					checkAppleIcon(144);
				}
			})
			.catch(e => {
				console.error('Site not configured!', e);
				let tries = 0;
				if (sessionStorage.getItem('siteConfig_tries')) {
					tries = Number.parseInt(sessionStorage.getItem('siteConfig_tries'), 10);
				}
				sessionStorage.setItem('siteConfig_tries', tries + 1);
				if (tries <= 5) {
					window.location.reload();
				} else {
					alert('Der er sket en fejl, prøv vent et par minutter og prøv igen');
				}
			});
	}, [dispatch, flags.loppeOptionToUseCustomLogos]);

	React.useEffect(() => {
		if (
			[
				'/logout',
				'/forgot-password',
				'/reset-password',
				'/activate-account',
				'/login',
				'/error/404',
				'/error/500'
			].includes(location.pathname)
		) {
			return;
		}
		if (
			((!firebaseService.getOrganisationId() || !firebaseService.getSignedSearchKey()) &&
				!sessionStorage.getItem('failsafe_reload')) ||
			Number.parseInt(sessionStorage.getItem('failsafe_reload'), 10) <= 3
		) {
			window.location.reload();
			let num = 0;
			if (sessionStorage.getItem('failsafe_reload')) {
				num = Number.parseInt(sessionStorage.getItem('failsafe_reload'), 10);
			}
			sessionStorage.setItem('failsafe_reload', num + 1);
		}
	}, [location]);

	return children;
}
