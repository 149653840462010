import React from 'react';
import authRoles from '../../../auth/authRoles';
import Profile from '@ameroservices-platform/loppe-boothownerui/app/main/apps/profil/Profile';

const ProfileAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/profile',
			element: <Profile />,
		},
	],
};

export default ProfileAppConfig;
