import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const initialState = {
	order: {},
	orderLine: {},
	products: [],
	product: {},
	productGroups: [],
	settlementOrderLinesBigQuery: null,
};

const customerAppSlice = createSlice({
	name: 'boothOwnerApp',
	initialState,
	reducers: {
		setCustomerOrder(state, action) {
			state.order[action.payload.id] = action.payload;
		},
		setCustomerOrderLine(state, action) {
			state.orderLine[action.payload.id] = action.payload;
		},
		setCustomerProduct(state, action) {
			state.product[action.payload.id] = action.payload;
		},
		setSettlementOrderLinesBigQuery(state, action) {
			state.settlementOrderLinesBigQuery = action.payload;
		},
	},
});

export const { setCustomerOrder, setCustomerOrderLine, setCustomerProduct, setSettlementOrderLinesBigQuery } =
	customerAppSlice.actions;

export const getCustomerSettlementOrderLinesFromBigQuery = () => (dispatch) => {
	return firebaseService
		.callFunctionByName('organisationCustomerOrderOrderlineGetSettlementOrderLinesFromBigQuery', {})
		.then((response) => {
			let settlementAccountAmountAfter = 0;

			const _customerSettlementOrderLines = response.data
				.reverse()
				.map((customerSettlementOrderLine) => {
					settlementAccountAmountAfter += customerSettlementOrderLine.amount;
					return {
						...customerSettlementOrderLine,
						bookedDate: customerSettlementOrderLine.bookedDate.value,
						createdDate: customerSettlementOrderLine.createdDate.value,
						settlementAccountAmountAfter,
					};
				})
				.reverse();
			dispatch(setSettlementOrderLinesBigQuery(_customerSettlementOrderLines));
		});
};

export const customerOrderListener = (customerUid, orderUid) => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.doc(orderUid)
		.onSnapshot((q) => {
			dispatch(setCustomerOrder({ ...q.data(), id: q.id }));
		});
};

export const customerOrderLineListener = (customerUid, orderUid, orderLineUid) => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.doc(orderUid)
		.collection('orderLines')
		.doc(orderLineUid)
		.onSnapshot((q) => {
			dispatch(setCustomerOrderLine({ ...q.data(), id: q.id }));
		});
};

export const customerProductListener = (productUid) => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('products')
		.doc(productUid)
		.onSnapshot((q) => {
			dispatch(setCustomerProduct({ ...q.data(), id: q.id }));
		});
};

export default customerAppSlice.reducer;
export const selectCustomerOrder = (state, id) => state.boothOwnerApp.order[id];
export const selectCustomerOrderLine = (state, id) => state.boothOwnerApp.orderLine[id];
export const selectCustomerProduct = (state, id) => state.boothOwnerApp.product[id];
export const selectSettlementOrderLinesBigQuery = (state) => state.boothOwnerApp.settlementOrderLinesBigQuery;
