import * as React from 'react';

const CustomButton = React.forwardRef(function CustomButton(props, ref) {
	let type = props.type ? props.type : 'button';
	return (
		<button type={type} {...props}>
			{props.title}
		</button>
	);
});

export default CustomButton;
