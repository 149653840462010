import * as React from 'react';
import { Snackbar, Alert } from '@mui/material';

const SuccessSnackBar = ({ handleClose, message }) => {
	const handleClickOnClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		handleClose();
	};

	return (
		<Snackbar
			sx={{ width: '50%', marginTop: '60px' }}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={true}
			autoHideDuration={5000}
			onClose={handleClickOnClose}
		>
			<Alert severity="success" variant={'filled'} onClose={handleClickOnClose} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default SuccessSnackBar;
