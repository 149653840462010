import error404PageConfig from '@ameroservices-platform/loppe-boothownerui/app/main/apps/error/404/error404PageConfig';
import error500PageConfig from '@ameroservices-platform/loppe-boothownerui/app/main/apps/error/500/error500PageConfig';
import SysAdminAppConfig from './sysAdmin/SysAdminAppConfig';
import ProfileAppConfig from '@ameroservices-platform/loppe-boothownerui/app/main/apps/profil/ProfilAppConfig';
import PayoutAppConfig from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/PayoutAppConfig';
import SaleAppConfig from '@ameroservices-platform/loppe-boothownerui/app/main/apps/sale/SaleAppConfig';
import BoothAppConfig from '@ameroservices-platform/loppe-boothownerui/app/main/apps/booth/BoothAppConfig';
import TermsAppConfig from '@ameroservices-platform/loppe-boothownerui/app/main/apps/terms/TermsAppConfig';

const appsConfigs = [
	BoothAppConfig,
	SaleAppConfig,
	PayoutAppConfig,
	ProfileAppConfig,
	SysAdminAppConfig,
	error404PageConfig,
	error500PageConfig,
	TermsAppConfig,
];

export default appsConfigs;
