import { createSlice } from '@reduxjs/toolkit';
import { CreateState } from '@ameroservices-platform/shared/enums/createState';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { setOrders } from '@ameroservices-platform/loppe-boothownerui/app/main/apps/globalStore';

const globalSlice = createSlice({
    name: 'booth',
    initialState: {
        createProductState: CreateState.IDLE,
        booth: null,
        subscription: null,
		productsWaiting: [],
    },
    reducers: {
        setCreateState: (state, action) => {
            state.createProductState = action.payload;
        },
        setBooth: (state, action) => {
            state.booth = action.payload;
        },
        setSubscription: (state, action) => {
            state.subscription = action.payload;
        },
		addProductWaiting: (state, action) => {
			state.productsWaiting.push(action.payload);
		},
		resetProductsWaiting: (state, action) => {
			state.productsWaiting = [];
		}
    },
});

export const {setCreateState, setBooth, setSubscription,addProductWaiting,resetProductsWaiting} = globalSlice.actions;

export default globalSlice.reducer;

export const subscriptionListener = (customerUid, subscriptionUid) => (dispatch) => {
    const db = firebaseService.getOrganisationRootDB();
    return db
        .collection('customers')
        .doc(customerUid)
        .collection('customer_subscriptions')
        .doc(subscriptionUid)
        .onSnapshot(
            (doc) => {
                dispatch(setSubscription({...doc.data(), id: doc.id}));
            },
            (error) => {
                console.error('subscriptionListener', {customerUid, subscriptionUid, error});
            }
        );
};

export const ticketTypeListener = (ticketTypeUid) => (dispatch) => {
    const db = firebaseService.getOrganisationRootDB();
    return db
        .collection('ticketTypes')
        .doc(ticketTypeUid)
        .onSnapshot(
            (doc) => {
                dispatch(setBooth({...doc.data(), id: doc.id}));
            },
            (error) => {
                console.error('ticketTypeListener', {ticketTypeUid, error});
            }
        );
};

export const archiveProduct = async (productUid, archivedBool) => {
    const response = await firebaseService.callFunctionByName('organisationProductArchiveProduct', {
        productUid: productUid, archived: archivedBool
    }, true);
    return response?.data;
}

export const cancelSubscriptionPayment = async (subscriptionUid) => {
    const response = await firebaseService.callFunctionByName(
        'organisationCustomerSubscriptionsCancelSubscriptionPayment',
        {
            subscriptionUid,
        },
        true
    );
    return response?.data;
};

export const updateSubscriptionPaymentType = async (subscriptionUid, paymentType) => {
    const response = await firebaseService.callFunctionByName(
        'organisationCustomerSubscriptionsSetPaymentType',
        {
            subscriptionUid,
            paymentType,
        },
        true
    );
    return response?.data;
};

export const terminateSubscription = async (subscriptionUid) => {
    const response = await firebaseService.callFunctionByName(
        'organisationCustomerSubscriptionsTerminateSubscription',
        {
            subscriptionUid,
        },
        true
    );
    return response?.data;
};

export const selectCreateProductState = (state) => state.booth.createProductState;
export const selectBooth = (state) => state.booth.booth;
export const selectSubscription = (state) => state.booth.subscription;
export const selectProductsWaiting = (state) => state.booth.productsWaiting;
