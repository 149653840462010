import Chart from 'react-apexcharts';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const ApexLineChart = ({ seriesWithNameAndData, title, height, options }) => {
	return (
		<Card className="w-full rounded-sm shadow-1 py-8">
			<div className="relative px-24 flex flex-row items-center justify-between">
				<div className="flex flex-col">
					<Typography className="h3 sm:h2">{title || ''}</Typography>
				</div>
			</div>

			<div className="relative" id="chart">
				<Chart options={options} series={seriesWithNameAndData} type="line" height={height || 300} />
			</div>
		</Card>
	);
};
export default ApexLineChart;
