import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	customerOrderLineListener,
	customerProductListener,
	selectCustomerOrderLine,
	selectCustomerProduct,
} from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/store/customerSlice';
import Paper from '@mui/material/Paper';

const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2,
});

function BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTableRow({
	settlementOrder,
	loppeOrderLineUid,
	settlementOrderLineOrderLine,
}) {
	const dispatch = useDispatch();

	const product = useSelector((state) => selectCustomerProduct(state, settlementOrderLineOrderLine.productUid));
	const loppeOrderLine = useSelector((state) => selectCustomerOrderLine(state, loppeOrderLineUid));

	useEffect(() => {
		const unsubFuncProduct = dispatch(customerProductListener(settlementOrderLineOrderLine.productUid));
		const unsubFuncOrderLine = dispatch(
			customerOrderLineListener(
				settlementOrder.loppeCustomerUid,
				settlementOrder.loppeOrderUid,
				loppeOrderLineUid
			)
		);
		return () => {
			unsubFuncProduct();
			unsubFuncOrderLine();
		};
	}, [dispatch, settlementOrderLineOrderLine]);

	return (
		<>
			{product && loppeOrderLine && (
				<Paper className="h-196 sm:h-96 mb-12">
					<div className="flex justify-between px-4 py-4" key={settlementOrderLineOrderLine.id}>
						<div>
							<div className="font-bold"># {product.productNumber}</div>
							<div className="truncate">{product.name}</div>
							<div className="truncate">{loppeOrderLine.qty} stk</div>
						</div>
						<div>
							<div className="truncate">
								Stk pris: {moneyFormatter.format(loppeOrderLine.unitPrice / 100.0)}
							</div>
							<div className="truncate ">
								Rabat:{' '}
								{settlementOrderLineOrderLine.discount
									? moneyFormatter.format(settlementOrderLineOrderLine.discount / 100.0)
									: '-'}
							</div>
							<div className="truncate">
								Total: {moneyFormatter.format(settlementOrderLineOrderLine.linePrice / 100.0)}
							</div>
						</div>
					</div>
				</Paper>
			)}
		</>
	);
}

export default BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTableRow;
