import { useSelector } from 'react-redux';
import { selectUser } from '@ameroservices-platform/loppe-boothownerui/app/auth/store/userSlice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FuseLoading from '@ameroservices-platform/shared/ui-components/FuseLoading';

function AuthRedirect() {
	const authUser = useSelector(selectUser);
	const navigate = useNavigate();

	useEffect(() => {
		if (authUser?.data?.email) {
			navigate('/sale');
		} else {
			navigate('/login');
		}
	}, [navigate, authUser]);

	return <FuseLoading />;
}

export default AuthRedirect;
