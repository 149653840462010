import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import FirebaseLoginTab from './tabs/FirebaseLoginTab';
import loginBg from '../img/login-bg.png';
import { useSelector } from 'react-redux';
import { selectUser } from '@ameroservices-platform/loppe-boothownerui/app/auth/store/userSlice';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';

const Root = styled('div')(({ theme }) => ({
	color: theme.palette.primary.contrastText,

	'& .Login-leftSection': {},

	'& .Login-rightSection': {
		backgroundImage: `url(${loginBg})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	'& .logo-icon': {
		height: '3rem',
	},
}));

function Login() {
	const navigate = useNavigate();

	return (
		<Root className="flex flex-col flex-auto items-center justify-center shrink-0 p-16 md:p-24">
			<motion.div
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				className="flex w-full max-w-400 md:max-w-2xl rounded-md shadow-2xl overflow-hidden"
			>
				<Card
					className="Login-leftSection pt-80 flex flex-col w-full max-w-400 items-center shadow-0 h-575"
					square
				>
					<CardContent className="flex-1 w-full h-full max-w-320">
						<FirebaseLoginTab />
					</CardContent>
				</Card>

				<div className="Login-rightSection hidden md:flex flex-1 items-center justify-center">
					<div className="w-10/12 mx-auto">
						<motion.div
							initial={{ opacity: 0, y: 40 }}
							animate={{
								opacity: 1,
								y: 0,
								transition: { delay: 0.2 },
							}}
						>
							<Typography variant="h3" color="inherit" className="font-semibold leading-tight">
								Loppemodul
							</Typography>
						</motion.div>

						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
							<Typography variant={'h6'} color="inherit" className="mt-32">
								Sælg dine brugte ting, og hold et overblik over dine salg - direkte fra din telefon
								eller computer.
							</Typography>
						</motion.div>
					</div>
				</div>
			</motion.div>
		</Root>
	);
}

export default Login;
