import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	customerOrderListener,
	selectCustomerOrder,
} from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/store/customerSlice';
import { correctionSettlementOrderReasonTypesTranslate } from '@ameroservices-platform/shared/enums/correctionSettlementOrderReasonTypes';
import { settlementAccountTypesTranslate } from '@ameroservices-platform/shared/enums/settlementAccountTypes';
import Paper from '@mui/material/Paper';
import FuseLoading from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseLoading';

function BoothOwnerAccountSettlementOrderLineCorrectionTable({ settlementOrderLine }) {
	const dispatch = useDispatch();

	const settlementOrder = useSelector((state) => selectCustomerOrder(state, settlementOrderLine.orderUid));

	useEffect(() => {
		const unsubFuncOrder = dispatch(
			customerOrderListener(settlementOrderLine.customerUid, settlementOrderLine.orderUid)
		);
		return () => {
			unsubFuncOrder();
		};
	}, [dispatch, settlementOrderLine]);

	return (
		<>
			{settlementOrder && (
				<Paper className="p-12 mb-12 bg-gray-200">
					<div key={settlementOrder.id} className="">
						<div className="flex justify-between">
							<div>
								<span className="font-bold">Fra konto:</span>{' '}
								{settlementAccountTypesTranslate[settlementOrder.fromAccountType]}
							</div>
							<div>
								<span className="font-bold">Til konto:</span>{' '}
								{settlementAccountTypesTranslate[settlementOrder.toAccountType]}
							</div>
						</div>

						<div className="mt-12">
							<span className="font-bold">Årsag:</span>{' '}
							{correctionSettlementOrderReasonTypesTranslate[settlementOrder.reason]}
						</div>
					</div>
				</Paper>
			)}
			{!settlementOrder && <FuseLoading />}
		</>
	);
}

export default BoothOwnerAccountSettlementOrderLineCorrectionTable;
