import React, { useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { useDispatch, useSelector } from 'react-redux';
import {
	customerOrderLineListener,
	customerOrderListener,
	selectCustomerOrder,
	selectCustomerOrderLine,
} from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/store/customerSlice';
import FuseLoading from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseLoading';
import BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTableRow from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTableRow';

function BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTable({ settlementOrderLine }) {
	const dispatch = useDispatch();

	const _settlementOrderLine = useSelector((state) => selectCustomerOrderLine(state, settlementOrderLine.id));
	const _settlementOrder = useSelector((state) => selectCustomerOrder(state, settlementOrderLine.orderUid));

	useEffect(() => {
		const unsubFuncOrderLine = dispatch(
			customerOrderLineListener(
				settlementOrderLine.customerUid,
				settlementOrderLine.orderUid,
				settlementOrderLine.id
			)
		);
		const unsubFuncOrder = dispatch(
			customerOrderListener(settlementOrderLine.customerUid, settlementOrderLine.orderUid)
		);
		return () => {
			unsubFuncOrderLine();
			unsubFuncOrder();
		};
	}, [dispatch, settlementOrderLine]);

	const settlementOrderLineOrderLines = useMemo(() => {
		if (_settlementOrderLine) {
			return _settlementOrderLine.orderLines;
		}
		return null;
	}, [_settlementOrderLine]);

	return (
		<>
			{settlementOrderLineOrderLines && _settlementOrder && (
				<Table size="small" aria-label="settlementOrderLineOrderLines">
					<TableBody>
						{Object.entries(settlementOrderLineOrderLines).map(
							([loppeOrderLineUid, settlementOrderLineOrderLine]) => (
								<BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTableRow
									key={loppeOrderLineUid}
									settlementOrder={_settlementOrder}
									loppeOrderLineUid={loppeOrderLineUid}
									settlementOrderLineOrderLine={settlementOrderLineOrderLine}
								/>
							)
						)}
					</TableBody>
				</Table>
			)}
			{!(settlementOrderLineOrderLines && _settlementOrder) && <FuseLoading />}
		</>
	);
}

export default BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTable;
