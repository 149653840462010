import {
	Button,
	Card,
	CardContent,
	Collapse,
	Icon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@mui/material';
import { useEffect } from 'react';
import * as React from 'react';
import CustomButton from '@ameroservices-platform/loppe-boothownerui/app/shared-components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { GetRuleByName } from '@ameroservices-platform/shared/utility/formValidation/HookFormRules';
import { useSelector, useDispatch } from 'react-redux';
import {
	createSettlementOrder,
	getCustomerSettlementAccountAmount,
	selectCustomer,
	selectCustomerSettlementAccountAmount
} from '@ameroservices-platform/loppe-boothownerui/app/main/apps/globalStore';
import settlementAccountTypes from '@ameroservices-platform/shared/enums/settlementAccountTypes';
import TextFieldForm from '@ameroservices-platform/shared/ui-components/fields/TextFieldForm';
import settlementOrderTypes, {
	settlementOrderLineTypesTranslate
} from '@ameroservices-platform/shared/enums/settlementOrderTypes';
import ConfirmationDialog from '@ameroservices-platform/loppe-boothownerui/app/ui-components/ConfirmationDialog';
import { showMessage } from '@ameroservices-platform/loppe-boothownerui/app/store/fuse/messageSlice';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import { Skeleton } from '@mui/lab';
import reducer, {
	selectSettlementOrderLinesBigQuery
} from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/store/customerSlice';
import withReducer from '@ameroservices-platform/loppe-boothownerui/app/store/withReducer';
import { getCustomerSettlementOrderLinesFromBigQuery } from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/store/customerSlice';
import Box from '@mui/material/Box';
import BoothOwnerAccountSettlementOrderLineSaleTable from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/BoothOwnerAccountSettlementOrderLineSaleTable';
import BoothOwnerAccountSettlementOrderLineCorrectionTable from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/BoothOwnerAccountSettlementOrderLineCorrectionTable';
import BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTable from '@ameroservices-platform/loppe-boothownerui/app/main/apps/payout/BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTable';
import { selectSetting } from '@ameroservices-platform/loppe-boothownerui/app/store/shared/frontendSlice';

function Payout() {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const selectedCustomer = useSelector(selectCustomer);
	const customerSettlementAccountAmount = useSelector(selectCustomerSettlementAccountAmount);
	const [confirmPayout, setConfirmPayout] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const settlementOrderLines = useSelector(selectSettlementOrderLinesBigQuery);
	const disablePayoutOnBoothownerUI = useSelector(state => selectSetting(state, 'disablePayoutOnBoothownerUI'));

	const { control, handleSubmit, reset, setError } = useForm({
		mode: 'onChange',
		defaultValues: {
			type: 'Billing',
			accNumber: selectedCustomer?.contactInfo?.billing?.firstname || '',
			regNumber: selectedCustomer?.contactInfo?.billing?.lastname || '',
			payoutAmount: ''
		}
	});

	useEffect(() => {
		dispatch(getCustomerSettlementOrderLinesFromBigQuery());
	}, [dispatch]);

	React.useEffect(() => {
		if (selectedCustomer) {
			reset({
				accNumber: selectedCustomer?.contactInfo?.billing?.accNumber || '',
				regNumber: selectedCustomer?.contactInfo?.billing?.regNumber || ''
			});
		}
	}, [selectedCustomer]);

	useEffect(() => {
		if (selectedCustomer) {
			dispatch(
				getCustomerSettlementAccountAmount({
					settlementAccountType: settlementAccountTypes.CUSTOMER
				})
			);
		}
	}, [dispatch, selectedCustomer]);

	const handleClickOnProfileLink = () => {
		navigate('/profile');
	};
	const [expand, setExpand] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [scroll, setScroll] = React.useState('paper');

	const handleClickOpen = scrollType => () => {
		setOpen(true);
		setScroll(scrollType);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	const handlePayoutBank = async result => {
		if (result) {
			const _payoutAmount = Math.abs(parseFloat(confirmPayout.payoutAmount.toString().replace(/,/g, '.')) * 100);

			setLoading(true);
			const payoutResponse = await createSettlementOrder({
				data: {
					settlementOrderType: settlementOrderTypes.PAYOUT_BANK,
					amount: _payoutAmount
				}
			});
			if (!payoutResponse) {
				dispatch(
					showMessage({ message: 'Beløbet kan ikke være større end saldoen på din konto', variant: 'error' })
				);
			} else {
				dispatch(
					getCustomerSettlementAccountAmount({
						settlementAccountType: settlementAccountTypes.CUSTOMER
					})
				);
				dispatch(
					showMessage({
						message: `${moneyFormatter.format(
							_payoutAmount / 100.0
						)} er blevet tilføjet til din udbetalingskonto`,
						variant: 'success'
					})
				);
			}
			setLoading(false);
		}
		setConfirmPayout(null);
	};

	async function onSubmit(data) {
		const _payoutAmount = parseFloat(data.payoutAmount.toString().replace(/,/g, '.')) * 100;
		if (_payoutAmount >= 1000 && _payoutAmount <= customerSettlementAccountAmount) {
			setConfirmPayout(data);
		} else {
			if (_payoutAmount < 1000) {
				setError('payoutAmount', { type: 'custom', message: 'Beløbet kan ikke være mindre end 10 kroner' });
			} else {
				setError('payoutAmount', {
					type: 'custom',
					message: 'Beløbet kan ikke være større end saldoen på din konto'
				});
			}
		}
	}

	return (
		<main className="flex-1 flex items-center justify-center">
			<Card className="max-w-xl mx-24 mx-8 my-20 sm:p-20 w-full">
				<CardContent>
					<h3 className="font-bold text-20">
						Hej {selectedCustomer?.contactInfo?.billing?.firstname}{' '}
						{selectedCustomer?.contactInfo?.billing?.lastname}!
					</h3>
					<p className="mt-4 text-18">
						Her kan du vælge det beløb, du ønsker at få udbetalt fra dit indestående. Bemærk venligst at
						udbetaling sker når butikken laver udbetalinger. Kontakt butikken for at høre mere.
					</p>
					<Button
						onClick={handleClickOpen('paper')}
						variant="outlined"
						className="rounded-md w-full md:w-1/2 my-20"
					>
						Se kontobevægelser
					</Button>

					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="mt-40 grid md:grid-cols-2 md:grid-row-1 gap-20 justify-items-start">
							<div className="md:col-start-2 md:col-end-3 md:row-start-1 md:row-end-2 w-full self-end">
								<p className="mb-20 text-gray-700 text-16">
									Det er muligt at rette konto- og registreringsnummer på{' '}
									<span
										className="text-blue-900 underline font-bold cursor-pointer"
										onClick={handleClickOnProfileLink}
									>
										profilsiden
									</span>
								</p>
								<div>
									<label className="block mb-5 text-16" htmlFor={'regNumber'}>
										Registreringsnummer
									</label>
									<TextFieldForm
										name={'regNumber'}
										control={control}
										disabled
										validations={GetRuleByName('regi')}
										InputProps={{
											classes: {
												root: 'rounded-lg border border-gray-100 bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
										fullWidth
									/>
								</div>
								<div className="mt-20"></div>
								<div>
									<label className="block mb-5 text-16" htmlFor={'accNumber'}>
										Kontonummer
									</label>
									<TextFieldForm
										name={'accNumber'}
										control={control}
										disabled
										validations={GetRuleByName('accNumber')}
										InputProps={{
											classes: {
												root: 'rounded-lg border border-gray-100 bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
										fullWidth
									/>
								</div>
							</div>
							<div className="md:col-start-1 md:col-end-2 md:row-start-1 md:row-end-1 w-full">
								<p className="text-gray-700 font-bold text-18">Indestående beløb</p>

								{typeof customerSettlementAccountAmount === 'number' && (
									<Typography className="text-32 font-bold">
										{customerSettlementAccountAmount &&
											moneyFormatter.format(customerSettlementAccountAmount / 100.0)}
									</Typography>
								)}
								{typeof customerSettlementAccountAmount !== 'number' && (
									<Typography className="text-32 font-bold">
										<Skeleton />
									</Typography>
								)}

								<div className="mt-20 relative">
									<div>
										<label className="block mb-5 text-16" htmlFor={'payoutAmount'}>
											Udbetaling
										</label>
										<TextFieldForm
											name={'payoutAmount'}
											control={control}
											validations={GetRuleByName('decimal')}
											InputProps={{
												classes: {
													root: 'rounded-lg border border-gray-100 bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
													input: 'p-0'
												}
											}}
											fullWidth
										/>
									</div>
								</div>
								<CustomButton
									disabled={disablePayoutOnBoothownerUI}
									title={
										disablePayoutOnBoothownerUI
											? 'Kontakt butik for udbetaling'
											: 'Udbetal indestående'
									}
									className="mt-48 rounded px-16 py-8 text-18 text-white bg-loppe-blue-default w-full"
									type={'submit'}
								/>
							</div>
						</div>
					</form>

					{!!confirmPayout && (
						<ConfirmationDialog
							onClose={handlePayoutBank}
							open={!!confirmPayout}
							content={
								'Er du sikker på at du vil have udbetalt ' +
								moneyFormatter.format(confirmPayout.payoutAmount) +
								'?'
							}
							okButton={'Udbetal til bank'}
							cancelButton={'Annuller'}
							maxWidth={'sm'}
							fullWidth
							loading={loading}
						/>
					)}
				</CardContent>
			</Card>

			<Dialog
				open={open}
				onClose={handleClose}
				scroll={scroll}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				maxWidth={'lg'}
				fullWidth={true}
			>
				<DialogTitle id="scroll-dialog-title">Dine kontobevægelser</DialogTitle>
				<DialogContent dividers={scroll === 'paper'}>
					<DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
						<Table>
							<TableHead className="hidden sm:table-header-group">
								<TableRow className="font-bold">
									<TableCell>&nbsp;</TableCell>
									<TableCell>Dato</TableCell>
									<TableCell>Type</TableCell>
									<TableCell>Beløb</TableCell>
									<TableCell>Saldo</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>

							{settlementOrderLines &&
								settlementOrderLines.map((settlementOrderLine, index) => {
									return (
										<TableBody>
											<TableRow
												className="hidden sm:table-row cursor-pointer h-64"
												onClick={() => setExpand(expand === index ? -1 : index)}
											>
												<TableCell></TableCell>
												<TableCell>
													<Typography className="truncate">
														{moment(settlementOrderLine.bookedDate).format('DD-MM-YY')}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography>
														{
															settlementOrderLineTypesTranslate[
																settlementOrderLine.settlementOrderType
															][settlementOrderLine.lineType]
														}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography>
														{moneyFormatter.format(settlementOrderLine.amount / 100.0)}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography>
														{moneyFormatter.format(
															settlementOrderLine.settlementAccountAmountAfter / 100.0
														)}
													</Typography>
												</TableCell>
												<TableCell>
													{settlementOrderLine.settlementOrderType !==
														settlementOrderTypes.PAYOUT_BANK && (
														<Icon
															fontSize={'medium'}
															className={expand === index ? '' : 'rotate-180'}
														>
															{expand === index ? 'arrow_drop_up' : 'arrow_drop_up'}
														</Icon>
													)}
												</TableCell>
											</TableRow>

											<TableRow
												className={`${
													expand === index ? 'w-full hidden sm:table-row' : 'hidden'
												} mt-2`}
											>
												<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
													{settlementOrderLine.settlementOrderType ===
														settlementOrderTypes.SALE && (
														<BoothOwnerAccountSettlementOrderLineSaleTable
															settlementOrderLine={settlementOrderLine}
														/>
													)}
													{settlementOrderLine.settlementOrderType ===
														settlementOrderTypes.CORRECTION && (
														<BoothOwnerAccountSettlementOrderLineCorrectionTable
															settlementOrderLine={settlementOrderLine}
														/>
													)}
													{settlementOrderLine.settlementOrderType ===
														settlementOrderTypes.PAYMENT_CUSTOMER_ACCOUNT && (
														<BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTable
															settlementOrderLine={settlementOrderLine}
														/>
													)}
												</TableCell>
											</TableRow>

											<TableRow className="table-row sm:hidden">
												<TableCell
													colSpan={6}
													className="flex flex-col my-12"
													onClick={() => setExpand(expand === index ? -1 : index)}
												>
													<div className="flex items-center justify-between">
														<Typography className="mr-12">
															{moment(settlementOrderLine.bookedDate).format('DD-MM-YY')}
														</Typography>

														<Typography>
															{moneyFormatter.format(settlementOrderLine.amount / 100.0)}
														</Typography>

														{settlementOrderLine.settlementOrderType !==
															settlementOrderTypes.PAYOUT_BANK && (
															<Icon
																fontSize={'medium'}
																className={expand === index ? '' : 'rotate-180'}
															>
																{expand === index ? 'arrow_drop_up' : 'arrow_drop_up'}
															</Icon>
														)}
													</div>
													<Typography className="truncate text-md font-bold">
														{
															settlementOrderLineTypesTranslate[
																settlementOrderLine.settlementOrderType
															][settlementOrderLine.lineType]
														}
													</Typography>
												</TableCell>

												<TableRow
													className={`${
														expand === index ? 'w-full table-row sm:hidden' : 'hidden'
													} mt-2`}
												>
													<TableCell
														style={{
															paddingBottom: 0,
															paddingTop: 0
														}}
														colSpan={6}
													>
														{settlementOrderLine.settlementOrderType ===
															settlementOrderTypes.SALE && (
															<BoothOwnerAccountSettlementOrderLineSaleTable
																settlementOrderLine={settlementOrderLine}
															/>
														)}
														{settlementOrderLine.settlementOrderType ===
															settlementOrderTypes.CORRECTION && (
															<BoothOwnerAccountSettlementOrderLineCorrectionTable
																settlementOrderLine={settlementOrderLine}
															/>
														)}
														{settlementOrderLine.settlementOrderType ===
															settlementOrderTypes.PAYMENT_CUSTOMER_ACCOUNT && (
															<BoothOwnerAccountSettlementOrderLinePaymentCustomerAccountTable
																settlementOrderLine={settlementOrderLine}
															/>
														)}
													</TableCell>
												</TableRow>
											</TableRow>
										</TableBody>
									);
								})}
						</Table>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Luk</Button>
				</DialogActions>
			</Dialog>
		</main>
	);
}

export default withReducer('boothOwnerApp', reducer)(Payout);
