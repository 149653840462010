import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
	submitLoginWithFireBase,
	submitLoginWithToken,
} from '@ameroservices-platform/loppe-boothownerui/app/auth/store/loginSlice';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import _ from '@ameroservices-platform/shared-fuse-lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { getParameterByName } from '@ameroservices-platform/shared/utility/index';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	email: yup.string().email('You must enter a valid email').required('You must enter a email'),
	password: yup.string().required('Please enter your password.'),
});

const defaultValues = {
	email: '',
	password: '',
	token: '',
};

function FirebaseLoginTab() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const login = useSelector(({ auth }) => auth.login);
	const user = useSelector(({ auth }) => auth.user);

	const { control, formState, handleSubmit, setError } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { isValid, dirtyFields, errors } = formState;

	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		login.errors.forEach((error) => {
			setError(error.type, {
				type: 'manual',
				message: error.message,
			});
		});
	}, [login.errors, setError]);

	const token = useMemo(() => getParameterByName('token', location.search), [location]);

	useEffect(() => {
		if (location && location.search && token) {
			dispatch(submitLoginWithToken({ token }));
		}
	}, [location, token]);

	useEffect(() => {
		if (user?.role?.length > 0 && user?.data?.email) {
			navigate('/');
		}
	}, [user, navigate]);

	function onSubmit(model) {
		dispatch(submitLoginWithFireBase(model));
	}

	const handleOnClickForgotPass = () => {
		navigate('/forgot-password');
	};

	return (
		<div className="w-full h-full">
			<form className="flex flex-col h-full" onSubmit={handleSubmit(onSubmit)}>
				<Typography variant="h5" className="font-bold mb-32">
					Log ind
				</Typography>
				<div className={'w-full flex-1'}>
					{token && (
						<Controller
							name="token"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									value={token}
									disabled
									className="mb-16 w-full"
									type="text"
									label="Token"
									error={!!errors.token}
									helperText={errors?.token?.message}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Icon className="text-20" color="action" fontSize={'large'}>
													key
												</Icon>
											</InputAdornment>
										),
									}}
									variant="outlined"
									required
								/>
							)}
						/>
					)}
					{!token && (
						<>
							<Controller
								name="email"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-16 w-full"
										type="text"
										label="Email"
										error={!!errors.email}
										helperText={errors?.email?.message}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Icon className="text-20" color="action" fontSize={'large'}>
														email
													</Icon>
												</InputAdornment>
											),
										}}
										variant="outlined"
										required
									/>
								)}
							/>
							<Controller
								name="password"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-16 w-full"
										type="password"
										label="Adgangskode"
										error={!!errors.password}
										helperText={errors?.password?.message}
										InputProps={{
											type: showPassword ? 'text' : 'password',
											endAdornment: (
												<InputAdornment position="end">
													<Icon
														className="text-20"
														color="action"
														fontSize={'large'}
														onClick={() => setShowPassword(!showPassword)}
													>
														{showPassword ? 'visibility' : 'visibility_off'}
													</Icon>
												</InputAdornment>
											),
										}}
										variant="outlined"
										required
									/>
								)}
							/>
						</>
					)}
					<div>
						<button
							className="font-bold mb-32 underline text-left inline-block"
							onClick={handleOnClickForgotPass}
							type={'button'}
						>
							Glemt adgangskode?
						</button>
					</div>
				</div>

				<div>
					<Button
						type="submit"
						variant="contained"
						className="w-full mx-auto mt-16 py-10 rounded-sm bg-loppe-blue-default hover:bg-loppe-blue-default"
						aria-label="LOG IN"
						disabled={_.isEmpty(dirtyFields) || !isValid}
						value="firebase"
					>
						Log ind
					</Button>
				</div>
			</form>
		</div>
	);
}

export default FirebaseLoginTab;
