import FuseDialog from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseDialog';
import { styled } from '@mui/material/styles';
import FuseMessage from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseMessage';
import FuseSuspense from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseSuspense';
import AppContext from '@ameroservices-platform/loppe-boothownerui/app/AppContext';
import SettingsPanel from '@ameroservices-platform/loppe-boothownerui/app/fuse-layouts/shared-components/SettingsPanel';
import React, { memo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRoutes } from 'react-router-dom';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';
import { customerListener } from '@ameroservices-platform/loppe-boothownerui/app/api/queries';
import { setCustomer } from '@ameroservices-platform/loppe-boothownerui/app/main/apps/globalStore';
import { logoutUser, selectUser } from '@ameroservices-platform/loppe-boothownerui/app/auth/store/userSlice';
import { Alert, Button } from '@mui/material';
import { selectSettingsLoaded } from '@ameroservices-platform/loppe-boothownerui/app/store/shared/frontendSlice';
import FuseLoading from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseLoading';

const Root = styled('div')(({ theme, config }) => ({
	...(config.mode === 'boxed' && {
		clipPath: 'inset(0)',
		maxWidth: `${config.containerWidth}px`,
		margin: '0 auto',
		boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
	}),
	...(config.mode === 'container' && {
		'& .container': {
			maxWidth: `${config.containerWidth}px`,
			width: '100%',
			margin: '0 auto'
		}
	})
}));

function Layout1(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const appContext = useContext(AppContext);
	const { routes } = appContext;
	const authUser = useSelector(selectUser);
	const dispatch = useDispatch();
	const settingsLoaded = useSelector(selectSettingsLoaded);
	const routesComponent = useRoutes(routes);
	const location = useLocation();

	useEffect(() => {
		if (authUser) {
			const unsubCustomerFunc = customerListener(authUser.uid, customer => {
				dispatch(setCustomer(customer));
			});

			return () => unsubCustomerFunc();
		}
	}, [authUser]);

	function handleLogout() {
		dispatch(logoutUser());
	}

	if (
		authUser?.uid &&
		![
			'/logout',
			'/forgot-password',
			'/reset-password',
			'/activate-account',
			'/login',
			'/error/404',
			'/error/500'
		].includes(location.pathname) &&
		!settingsLoaded
	) {
		return <FuseLoading />;
	}

	return (
		<Root id="fuse-layout" config={config} className="w-full flex">
			{config.leftSidePanel.display && <LeftSideLayout1 />}

			<div className="flex flex-auto min-w-0">
				{config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

				<main id="fuse-main" className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
					{config.toolbar.display && (
						<ToolbarLayout1 className={config.toolbar.style === 'fixed' && 'sticky top-0'} />
					)}

					<div className="flex flex-col flex-auto min-h-0 relative z-10">
						<FuseDialog />

						<FuseSuspense>{routesComponent}</FuseSuspense>

						{props.children}
					</div>

					{config.footer.display && (
						<FooterLayout1 className={config.footer.style === 'fixed' && 'sticky bottom-0'} />
					)}
				</main>

				{config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
			</div>

			{config.rightSidePanel.display && <RightSideLayout1 />}
			<FuseMessage />
			{authUser?.claims?.impersonate && (
				<div className={'fixed bottom-0 w-full z-9999'}>
					<Alert
						severity={'error'}
						variant={'filled'}
						className={'rounded-none'}
						action={
							<Button color={'primary'} variant={'contained'} onClick={handleLogout}>
								Klik her for at logge ud
							</Button>
						}
					>
						Du bruger lige nu applikationen som bruger{' '}
						<span className={'font-bold'}>{authUser?.data?.displayName || '?'}</span> (
						{authUser?.data?.email || '?'})
					</Alert>
				</div>
			)}
		</Root>
	);
}

export default memo(Layout1);
