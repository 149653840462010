const navigationConfig = [
	{
		id: 'user',
		title: '',
		type: 'group',
		children: [
			{
				id: 'profile',
				title: 'Min profil',
				type: 'item',
				icon: 'person',
				url: '/profile',
				exact: true,
			},
			{
				id: 'sale',
				title: 'Salg',
				type: 'item',
				icon: 'trending_up',
				url: '/sale',
				exact: true,
			},

			{
				id: 'boothAdmin',
				title: 'Administrer stande',
				type: 'item',
				icon: 'settings',
				url: '/booths',
				exact: true,
			},
			{
				id: 'payout',
				title: 'Udbetaling',
				type: 'item',
				icon: 'attach_money',
				url: '/payout',
				exact: true,
			},
			{
				id: 'createProduct',
				title: 'Opret vare',
				type: 'collapse',
				icon: 'add',
				exact: true,
				children: [],
			},
		],
	},
];

export default navigationConfig;
