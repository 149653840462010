import firebaseService from '@ameroservices-platform/shared/services/firebase';

export const customerListener = (customerUid, callback) => {
	const db = firebaseService.getRootDB();
	const organisationUid = firebaseService.getOrganisationId() || '8Vm4TosHU5q9TsBBb4EE';
	return db
		.collection('organisations')
		.doc(organisationUid)
		.collection('customers')
		.doc(customerUid)
		.onSnapshot(
			(doc) => {
				const customer = { ...doc.data(), id: doc.id };
				callback(customer);
			},
			(error) => {
				console.error('customerListener', { customerUid, error });
			}
		);
};

export const customerSubscriptionListener = (customerUid, callback) => {
	const db = firebaseService.getRootDB();
	const organisationUid = firebaseService.getOrganisationId() || '8Vm4TosHU5q9TsBBb4EE';
	return db
		.collection('organisations')
		.doc(organisationUid)
		.collection('customers')
		.doc(customerUid)
		.collection('customer_subscriptions')
		.where('deleted', '==', false)
		.onSnapshot(
			(docs) => {
				const subscriptions = [];
				docs.forEach((doc) => {
					subscriptions.push({ ...doc.data(), id: doc.id });
				});
				callback(subscriptions);
			},
			(error) => {
				console.error('customerSubscriptionListener', { customerUid, error });
			}
		);
};

export const ticketTypeListener = (callback) => {
	const db = firebaseService.getRootDB();
	const organisationUid = firebaseService.getOrganisationId() || '8Vm4TosHU5q9TsBBb4EE';
	return db
		.collection('organisations')
		.doc(organisationUid)
		.collection('ticketTypes')
		.where('state', '==', 'active')
		.onSnapshot(
			(docs) => {
				const ticketTypes = [];
				docs.forEach((doc) => {
					ticketTypes.push({ ...doc.data(), id: doc.id });
				});
				callback(ticketTypes);
			},
			(error) => {
				console.error('ticketTypeListener', { error });
			}
		);
};

export const ticketTypeDocListener = (ticketTypeUid, callback) => {
	const db = firebaseService.getRootDB();
	const organisationUid = firebaseService.getOrganisationId() || '8Vm4TosHU5q9TsBBb4EE';
	return db
		.collection('organisations')
		.doc(organisationUid)
		.collection('ticketTypes')
		.doc(ticketTypeUid)
		.onSnapshot(
			(doc) => {
				callback({ ...doc.data(), id: doc.id });
			},
			(error) => {
				console.error('ticketTypeDocListener', { ticketTypeUid, error });
			}
		);
};

export const productByTicketTypeUidListener = async (ticketTypeUids, callback) => {
	const db = firebaseService.getRootDB();
	const organisationUid = firebaseService.getOrganisationId() || '8Vm4TosHU5q9TsBBb4EE';
	const arr = await Promise.all(
		ticketTypeUids.map((uid) =>
			db
				.collection('organisations')
				.doc(organisationUid)
				.collection('products')
				.where('ticketTypeUid', '==', uid)
				.where('state', '==', 'active')
				.get()
		)
	);
	const productsByTicketType = {};

	arr.forEach((docs) => {
		docs.forEach((doc) => {
			doc = {
				...doc.data(),
				id: doc.id,
			};
			if (productsByTicketType[doc.ticketTypeUid]) {
				productsByTicketType[doc.ticketTypeUid].push(doc);
			} else {
				productsByTicketType[doc.ticketTypeUid] = [doc];
			}
		});
	});
	callback(productsByTicketType);
};

export const createProduct = async ({ name, state, productSalePrice, ticketTypeUid, type }) => {
	const resp = await firebaseService.callFunctionByName(
		'organisationProductCreateProduct',
		{
			name,
			state,
			productSalePrice,
			ticketTypeUid,
			type,
		},
		true
	);
	return resp.data;
};
