import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const frontendSlice = createSlice({
	name: 'frontend',
	initialState: {
		settings: null,
		loaded: false
	},
	reducers: {
		setSettings(state, action) {
			state.settings = action.payload;
			state.loaded = true;
		}
	}
});

export const { setSettings } = frontendSlice.actions;

export default frontendSlice.reducer;

export const selectSettings = state => state.shared.frontend.settings;
export const selectSetting = (state, setting) => _.get(state.shared.frontend.settings, setting);
export const selectSettingsLoaded = state => state.shared.frontend.loaded;
