import FuseScrollbars from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import Navigation from '@ameroservices-platform/loppe-boothownerui/app/fuse-layouts/shared-components/Navigation';
import clsx from 'clsx';
import { memo } from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCustomer } from '@ameroservices-platform/loppe-boothownerui/app/main/apps/globalStore';
import { selectSetting } from '@ameroservices-platform/loppe-boothownerui/app/store/shared/frontendSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Root = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	color: theme.palette.text.primary,
	'& ::-webkit-scrollbar-thumb': {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
		}`,
	},
	'& ::-webkit-scrollbar-thumb:active': {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
		}`,
	},
}));

const StyledContent = styled(FuseScrollbars)(({ theme }) => ({
	overscrollBehavior: 'contain',
	overflowX: 'hidden',
	overflowY: 'auto',
	WebkitOverflowScrolling: 'touch',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% 40px, 100% 10px',
	backgroundAttachment: 'local, scroll',
}));

function NavbarStyle1Content({ toggleLargeNav, ...props }) {
	const flags = useFlags();
	const customer = useSelector(selectCustomer);
	const sidebarLogo = useSelector((state) => selectSetting(state, 'logos.sidebar'));

	const customerHasContactBillingInfo = customer && customer.contactInfo && customer.contactInfo.billing;

	return (
		<Root
			className={clsx(
				'flex flex-auto flex-col overflow-hidden h-full bg-loppe-blue-default text-white',
				props.className
			)}
		>
			{flags.loppeOptionToUseCustomLogos && sidebarLogo && (
				<div className="flex flex-row items-center shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 mt-40">
					<div className="flex justify-center mx-4 w-full">
						<img className="max-h-36" src={'https://storage.googleapis.com/' + sidebarLogo} alt="logo" />
					</div>
				</div>
			)}
			<StyledContent option={{ suppressScrollX: true, wheelPropagation: false }}>
				<div className="flex items-center justify-center h-124 mt-40">
					{customer && customerHasContactBillingInfo && (
						<div className="flex flex-col items-center">
							{customer.img && (
								<div>
									<img src={customer.img} alt="User profile img" />
								</div>
							)}
							{!customer.img && (
								<div className="w-80 h-80 rounded-full bg-gray-50 flex items-center justify-center">
									<Typography variant="h3" className="text-loppe-gray-dark font-bold">
										{customer.contactInfo.billing.firstname[0]}
									</Typography>
								</div>
							)}
							<Typography variant="h5" className="mt-16 text-loppe-gray-light">
								{customer.contactInfo.billing.firstname} {customer.contactInfo.billing.lastname}
							</Typography>
							<Typography className="text-base text-loppe-gray-light">
								{customer.contactInfo.billing.email}
							</Typography>
						</div>
					)}
				</div>

				<Navigation layout="vertical" />
			</StyledContent>
		</Root>
	);
}

export default memo(NavbarStyle1Content);
