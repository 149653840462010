import FuseSearch from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseSearch';
import { ThemeProvider } from '@mui/material/styles';
import FuseShortcuts from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseShortcuts';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import NavbarToggleButton from '@ameroservices-platform/loppe-boothownerui/app/fuse-layouts/shared-components/NavbarToggleButton';
import UserMenu from '@ameroservices-platform/loppe-boothownerui/app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	selectMainTheme,
	selectToolbarTheme,
} from '@ameroservices-platform/loppe-boothownerui/app/store/fuse/settingsSlice';
import OrganisationSelector from '@ameroservices-platform/loppe-boothownerui/app/fuse-layouts/shared-components/organisationSelector/OrganisationSelector';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
// import SyncAdminModal from '@ameroservices-platform/loppe-boothownerui/app/fuse-layouts/shared-components/SyncAdminModal';
// import SyncAdminModal from '@ameroservices-platform/shared/base-components/layout/SyncAdminModal';
import resyncTypes from '@ameroservices-platform/shared/enums/resyncTypes';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

function ToolbarLayout1(props) {
	const flags = useFlags();
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const navbar = useSelector(({ fuse }) => fuse.navbar);
	const toolbarTheme = useSelector(selectToolbarTheme);
	const mainTheme = useSelector(selectMainTheme);
	const role = useSelector(({ auth }) => (auth.user.claims ? auth.user.claims.role : 'user'));
	const [syncDialog, setSyncDialog] = useState(false);
	const allowedOrganisations = firebaseService.getOrganisationSetting('allowedOrganisations');

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className={clsx('flex relative z-20 shadow-md', props.className)}
				color="default"
				sx={{ backgroundColor: toolbarTheme.palette.background.paper }}
				position="static"
			>
				<Toolbar className="p-0 min-h-48 md:min-h-64">
					<div className="flex flex-1 px-16">
						{config.navbar.display && config.navbar.position === 'left' && (
							<>
								<Hidden lgDown>
									{(config.navbar.style === 'style-3' || config.navbar.style === 'style-3-dense') && (
										<NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
									)}

									{config.navbar.style === 'style-1' && !navbar.open && (
										<NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
									)}
								</Hidden>

								<Hidden lgUp>
									<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
								</Hidden>
							</>
						)}

						{/*<Hidden lgDown>*/}
						{/*	<FuseShortcuts />*/}
						{/*</Hidden>*/}
					</div>

					<div className="flex items-center px-8 h-full overflow-x-auto">
						{/*<LanguageSwitcher />*/}

						{/*<AdjustFontSize />*/}

						{/*<FullScreenToggle />*/}

						{allowedOrganisations && allowedOrganisations.length >= 2 && <OrganisationSelector />}
						{/*<FuseSearch />*/}
						{/*{role === 'ameroAdmin' && (*/}
						{/*	<>*/}
						{/*		<Tooltip title="Synkroniserings administration">*/}
						{/*			<IconButton className="w-40 h-40" onClick={() => setSyncDialog(true)}>*/}
						{/*				<Icon>sync</Icon>*/}
						{/*			</IconButton>*/}
						{/*		</Tooltip>*/}
						{/*		<SyncAdminModal*/}
						{/*			open={syncDialog}*/}
						{/*			onClose={() => {*/}
						{/*				setSyncDialog(false);*/}
						{/*			}}*/}
						{/*			mainTheme={mainTheme}*/}
						{/*			resyncTypes={[resyncTypes.CUSTOMERS, resyncTypes.ORDERS]}*/}
						{/*		/>*/}
						{/*	</>*/}
						{/*)}*/}

						{/*<Hidden lgUp>*/}
						{/*  <ChatPanelToggleButton />*/}
						{/*</Hidden>*/}

						{/*<QuickPanelToggleButton />*/}

						<UserMenu />
					</div>

					{config.navbar.display && config.navbar.position === 'right' && (
						<>
							<Hidden lgDown>
								{!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
							</Hidden>

							<Hidden lgUp>
								<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
							</Hidden>
						</>
					)}
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default memo(ToolbarLayout1);
