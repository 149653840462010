import { authRoles } from '@ameroservices-platform/loppe-boothownerui/app/auth';
import ResetPasswordPage from './ResetPasswordPage';

const ResetPasswordPageConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	routes: [
		{
			path: 'reset-password',
			element: <ResetPasswordPage />,
		},
	],
};

export default ResetPasswordPageConfig;
