import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function Terms() {
	return (
		<div className="px-8 pt-40">
			<Paper className="p-24">
				<Typography variant="h5">Overskrift</Typography>
				<Typography>Indsæt cookie, gdpr og lign. her</Typography>
			</Paper>
		</div>
	);
}

export default Terms;
