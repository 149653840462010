import React, { useEffect, useState } from 'react';
import { Chip, Icon } from '@mui/material';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

function OrganisationSelectorChip(props) {
	const [organisationName, setOrganisationName] = useState('Indlæser...');
	useEffect(() => {
		const organisationId = firebaseService.getOrganisationId();
		if (!organisationId) {
			return setOrganisationName('Vælge butik');
		}
		const allowedOrganisations = firebaseService.getOrganisationSetting('allowedOrganisations');
		if (!allowedOrganisations) {
			return setOrganisationName('Vælg butik');
		}
		const organisation = allowedOrganisations.find((o) => o.id === organisationId);
		if (!organisation) {
			return setOrganisationName('Vælg butik');
		}
		setOrganisationName(organisation.name);

	}, []);

	return (
		<Chip
			icon={<Icon>business</Icon>}
			label={organisationName}
			onClick={(e) => {
				e.preventDefault();
			}}
			variant="outlined"
		/>
	);
}

// export default withReducer('quickPanel', reducer)(React.memo(QuickPanel));
export default OrganisationSelectorChip;
