import * as React from 'react';
import { Card, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCustomer } from '@ameroservices-platform/loppe-boothownerui/app/main/apps/globalStore';
import CustomButton from '@ameroservices-platform/loppe-boothownerui/app/shared-components/CustomButton';
import { useForm } from 'react-hook-form';
import { updateCustomerByFunction } from '@ameroservices-platform/shared/base-components/customer/customerSlice';
import SuccessSnackBar from '@ameroservices-platform/loppe-boothownerui/app/main/apps/profil/SuccessSnackBar';
import { GetRuleByName } from '@ameroservices-platform/shared/utility/formValidation/HookFormRules';
import TextFieldForm from '@ameroservices-platform/shared/ui-components/fields/TextFieldForm';

function Profile() {
	const selectedCustomer = useSelector(selectCustomer);

	const [success, setSuccess] = React.useState(false);
	const [error, setError] = React.useState(false);

	const { register, handleSubmit, reset, formState, control } = useForm({
		mode: 'onChange',
		defaultValues: {
			type: 'Billing',
			firstname: selectedCustomer?.contactInfo?.billing?.firstname || '',
			lastname: selectedCustomer?.contactInfo?.billing?.lastname || '',
			email: selectedCustomer?.contactInfo?.billing?.email || '',
			phone: selectedCustomer?.contactInfo?.billing?.phone || '',
			address: selectedCustomer?.contactInfo?.billing?.address || '',
			postalCode: selectedCustomer?.contactInfo?.billing?.postalCode || '',
			city: selectedCustomer?.contactInfo?.billing?.city || '',
			countryCode: selectedCustomer?.contactInfo?.billing?.countryCode || '',
			accNumber: selectedCustomer?.contactInfo?.billing?.accNumber || '',
			regNumber: selectedCustomer?.contactInfo?.billing?.regNumber || ''
		}
	});

	const { isValid: isFormValid } = formState;

	React.useEffect(() => {
		if (selectedCustomer) {
			reset({
				firstname: selectedCustomer?.contactInfo?.billing?.firstname || '',
				lastname: selectedCustomer?.contactInfo?.billing?.lastname || '',
				email: selectedCustomer?.contactInfo?.billing?.email || '',
				phone: selectedCustomer?.contactInfo?.billing?.phone || '',
				address: selectedCustomer?.contactInfo?.billing?.address || '',
				postalCode: selectedCustomer?.contactInfo?.billing?.postalCode || '',
				city: selectedCustomer?.contactInfo?.billing?.city || '',
				countryCode: selectedCustomer?.contactInfo?.billing?.countryCode || '',
				accNumber: selectedCustomer?.contactInfo?.billing?.accNumber || '',
				regNumber: selectedCustomer?.contactInfo?.billing?.regNumber || ''
			});
		}
	}, [selectedCustomer]);

	const onSubmit = async data => {
		if (isFormValid) {
			const newContactInfos = { ...data };
			delete newContactInfos.defaultValues;
			delete newContactInfos.mode;
			const changesObject = {};
			newContactInfos.birthday = data.birthday ? data.birthday.startOf('day').valueOf() : '';
			newContactInfos.countryCode = data.countryCode;
			changesObject.contactInfo = newContactInfos;
			delete changesObject.contactInfo.type;
			try {
				const res = await updateCustomerByFunction(null, changesObject);
				if (res.data.error === false) {
					setSuccess(true);
				}
			} catch (e) {
				setError(true);
				console.error(e);
			}
		}
	};

	const handleCloseSnackBar = () => {
		setSuccess(false);
	};

	return (
		<main className="flex-1 flex justify-center items-center mx-auto max-w-xl relative pb-48">
			{success && (
				<SuccessSnackBar handleClose={handleCloseSnackBar} message={'Dine oplysninger er blevet opdateret'} />
			)}
			<Card className="flex items-stretch mx-24 mt-48 sm:p-20 w-full h-full md:h-512">
				<div>
					<div className="flex w-full px-20 md:px-8">
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="flex-1 gap-20 mx-auto grid grid-cols-1 grid-rows-3 sm:grid-cols-1 sm:grid-rows-1 md:grid-rows-1 md:grid-cols-3 md:mx-0"
						>
							<div className="grid grid-cols-1 gap-y-14 profile-grid-rows -mt-4">
								<div className="flex flex-col items-center md:items-start row-span-2 my-auto">
									{selectedCustomer && (
										<div className="flex flex-col items-center">
											{selectedCustomer?.img && (
												<div className="w-80 h-80 rounded-full bg-gray-50 flex items-center justify-center shadow-lg">
													<img src={selectedCustomer.img} alt="User profile" />
												</div>
											)}
											{!selectedCustomer.img && (
												<div className="w-80 h-80 rounded-full bg-gray-50 flex items-center justify-center shadow-lg">
													<Typography variant="h3" className="text-[#8E8E8E] font-bold">
														{selectedCustomer &&
															selectedCustomer?.contactInfo?.billing?.firstname[0]}
													</Typography>
												</div>
											)}
											<Typography className="mt-6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
												{selectedCustomer?.contactInfo?.billing?.firstname}{' '}
												{selectedCustomer?.contactInfo?.billing?.lastname}
											</Typography>
										</div>
									)}
									{!selectedCustomer && <div className="flex flex-col items-center"></div>}
								</div>

								<div>
									<label className="block mb-5 text-16" htmlFor={'firstname'}>
										Fornavn
									</label>
									<TextFieldForm
										name={'firstname'}
										control={control}
										validations={GetRuleByName('firstname')}
										InputProps={{
											classes: {
												root: 'rounded-lg border bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
									/>
								</div>
								<div>
									<label className="block mb-5 text-16" htmlFor={'lastname'}>
										Efternavn
									</label>
									<TextFieldForm
										name={'lastname'}
										control={control}
										validations={GetRuleByName('lastname')}
										InputProps={{
											classes: {
												root: 'rounded-lg border bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
									/>
								</div>
								<div>
									<label className="block mb-5 text-16" htmlFor={'phone'}>
										Telefon
									</label>
									<TextFieldForm
										name={'phone'}
										control={control}
										validations={GetRuleByName('phone')}
										InputProps={{
											classes: {
												root: 'rounded-lg border bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
									/>
								</div>
							</div>
							<div className="grid grid-cols-1 gap-y-14 profile-grid-rows mt-36 md:mt-80">
								<div>
									<label className="block mb-5 text-16" htmlFor={'email'}>
										Email
									</label>
									<TextFieldForm
										name={'email'}
										disabled={true}
										control={control}
										validations={GetRuleByName('email')}
										InputProps={{
											classes: {
												root: 'rounded-lg border bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
									/>
								</div>
								<div>
									<label className="block mb-5 text-16" htmlFor={'address'}>
										Adresse
									</label>
									<TextFieldForm
										name={'address'}
										control={control}
										validations={GetRuleByName('address')}
										InputProps={{
											classes: {
												root: 'rounded-lg border bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
									/>
								</div>
								<div>
									<label className="block mb-5 text-16" htmlFor={'postalCode'}>
										Postnummer
									</label>
									<TextFieldForm
										name={'postalCode'}
										control={control}
										validations={GetRuleByName('postalCode')}
										InputProps={{
											classes: {
												root: 'rounded-lg border bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
									/>
								</div>
								<div>
									<label className="block mb-5 text-16" htmlFor={'city'}>
										By
									</label>
									<TextFieldForm
										name={'city'}
										control={control}
										validations={GetRuleByName('city')}
										InputProps={{
											classes: {
												root: 'rounded-lg border bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
									/>
								</div>
							</div>
							<div className="grid grid-cols-1 gap-y-14 profile-grid-rows md:mt-80">
								<div>
									<label className="block mb-5 text-16" htmlFor={'regNumber'}>
										Registreringsnummer
									</label>
									<TextFieldForm
										name={'regNumber'}
										control={control}
										validations={GetRuleByName('regi')}
										InputProps={{
											classes: {
												root: 'rounded-lg border bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
									/>
								</div>
								<div>
									<label className="block mb-5 text-16" htmlFor={'accNumber'}>
										Kontonummer
									</label>
									<TextFieldForm
										name={'accNumber'}
										control={control}
										validations={GetRuleByName('accNumber')}
										InputProps={{
											classes: {
												root: 'rounded-lg border bg-loppe-gray-lighter text-base leading-6 px-16 py-8 w-full',
												input: 'p-0'
											}
										}}
									/>
								</div>
								<div className="flex items-end">
									<CustomButton
										title="Nulstil kodeord"
										className="rounded px-16 py-8 text-18 text-white bg-loppe-blue-default w-full"
									/>
								</div>
								<div className="flex items-end">
									<CustomButton
										type={'submit'}
										title="Gem ændringer"
										className="rounded px-16 py-8 text-18 text-white bg-loppe-blue-default w-full"
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Card>
		</main>
	);
}

export default Profile;

// const Help = () => {
// 	return (
// 		<form onSubmit={handleSubmit(onSubmit, onError)} ref={formRef} className="flex flex-col">
// 			<Grid container spacing={1}>
// 				<Grid item sm={6}></Grid>
// 				<Grid item sm={6}>
// 					<TextFieldForm
// 						control={control}
// 						className="my-2 bg-white"
// 						type="text"
// 						name="lastname"
// 						label="Efternavn"
// 						validations={GetRuleByName('lastname')}
// 						variant="outlined"
// 						fullWidth
// 						hideHelperText
// 					/>
// 				</Grid>
//
// 				<Grid item sm={12}>
// 					<TextFieldForm
// 						control={control}
// 						className="my-2 bg-white"
// 						type="email"
// 						name="email"
// 						label="Email"
// 						validations={GetRuleByName('email')}
// 						variant="outlined"
// 						fullWidth
// 						hideHelperText
// 					/>
// 				</Grid>
// 				<Grid item sm={6}>
// 					<TextFieldForm
// 						control={control}
// 						className="my-2 bg-white"
// 						type="text"
// 						name="phone"
// 						label="Telefon"
// 						validations={GetRuleByName('phone')}
// 						variant="outlined"
// 						fullWidth
// 						hideHelperText
// 					/>
// 				</Grid>
// 				<Grid item sm={6}>
// 					<TextFieldForm
// 						control={control}
// 						className="my-2 bg-white"
// 						type="text"
// 						name="address"
// 						label="Adresse"
// 						validations={GetRuleByName('address')}
// 						variant="outlined"
// 						fullWidth
// 						hideHelperText
// 					/>
// 				</Grid>
//
// 				<Grid item sm={6}>
// 					<TextFieldForm
// 						control={control}
// 						className="my-2 bg-white"
// 						type="text"
// 						name="postalCode"
// 						label="Postnummer"
// 						validations={GetRuleByName('postal')}
// 						variant="outlined"
// 						fullWidth
// 						hideHelperText
// 					/>
// 				</Grid>
// 				<Grid item sm={6}>
// 					<TextFieldForm
// 						control={control}
// 						className="my-2 bg-white"
// 						type="text"
// 						name="city"
// 						label="By"
// 						validations={GetRuleByName('city')}
// 						variant="outlined"
// 						fullWidth
// 						hideHelperText
// 					/>
// 				</Grid>
// 				<Grid item sm={6}>
// 					<TextFieldForm
// 						control={control}
// 						className="my-2 bg-white"
// 						type="tel"
// 						name="regNumber"
// 						label="Bank registreringsnummer"
// 						validations={GetRuleByName('regi')}
// 						variant="outlined"
// 						fullWidth
// 						hideHelperText
// 					/>
// 				</Grid>
// 				<Grid item sm={6}>
// 					<TextFieldForm
// 						control={control}
// 						className="my-2 bg-white"
// 						type="tel"
// 						name="accNumber"
// 						label="Bank kontonummer"
// 						validations={GetRuleByName('accNumber')}
// 						variant="outlined"
// 						fullWidth
// 						hideHelperText
// 					/>
// 				</Grid>
// 			</Grid>
// 			<Button
// 				type="submit"
// 				className="flex items-center justify-around flex-col bg-blue-300 hover:bg-blue-300 text-white text-xs border-1 border-gray-400 rounded-sm h-48 p-8 absolute bottom-10 right-14 left-14"
// 			>
// 				<Typography>Gem oplysninger</Typography>
// 			</Button>
// 		</form>
// 	);
// };
