import React from 'react';
import Terms from '@ameroservices-platform/loppe-boothownerui/app/main/apps/terms/Terms';

const TermsAppConfig = {
	settings: {
		layout: {},
	},
	routes: [
		{
			path: '/terms',
			element: <Terms />,
		},
	],
};

export default TermsAppConfig;
