const productGroupState = {
	INACTIVE: 'inactive',
	ACTIVE: 'active',
	RESERVED: 'reserved',
	EXPIRED: 'expired',
	EXPIRES: 'expires',
	CANCELED: 'canceled',
	UNKNOWN: 'unknown'
};
export const productGroupStateTranslate = {
	[productGroupState.INACTIVE]: 'Inaktiv',
	[productGroupState.ACTIVE]: 'Aktiv',
	[productGroupState.RESERVED]: 'Reserveret',
	[productGroupState.EXPIRED]: 'Udløbet',
	[productGroupState.EXPIRES]: 'Udløber snart',
	[productGroupState.CANCELED]: 'Annulleret',
	[productGroupState.UNKNOWN]: 'Ukendt'
};

export default productGroupState;
