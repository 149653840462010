/**
 * Authorization Roles
 */
const authRoles = {
	ameroAdmin: ['ameroAdmin'],
	reseller: ['ameroAdmin', 'reseller'],
	admin: ['ameroAdmin', 'amero', 'admin'],
	user: ['ameroAdmin', 'amero', 'admin', 'staff', 'user', 'customer'],
	onlyGuest: [],
};

export default authRoles;
