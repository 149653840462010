import * as React from 'react';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import loginBg from '../img/login-bg.png';
import { useSelector } from 'react-redux';
import { selectUser } from '@ameroservices-platform/loppe-boothownerui/app/auth/store/userSlice';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { useForm } from 'react-hook-form';
import TextFieldForm from '@ameroservices-platform/shared/ui-components/fields/TextFieldForm';
import { CircularProgress, Icon } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
	color: theme.palette.primary.contrastText,

	'& .Login-leftSection': {},

	'& .Login-rightSection': {
		backgroundImage: `url(${loginBg})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	'& .logo-icon': {
		height: '3rem',
	},
}));

function ForgotPassword() {
	const navigate = useNavigate();
	const authUser = useSelector(selectUser);
	const [loading, setLoading] = React.useState(false);
	const { watch, control, formState, handleSubmit, setValue } = useForm({
		mode: 'onChange',
		defaultValues: {
			email: '',
		},
	});
	const { isValid, isSubmitted, isSubmitting, isSubmitSuccessful } = formState;

	React.useEffect(() => {
		if (authUser?.role?.length > 0 && authUser?.data?.displayName) {
			navigate('/');
		}
	}, [authUser, navigate]);

	const handleClickOnLogin = () => {
		navigate('/login');
	};

	async function onSubmit(data) {
		setLoading(true);
		await firebaseService.callFunctionByName('organisationCustomerSendResetCustomerPassword', {
			email: data.email,
		});
		setLoading(false);
	}

	return (
		<Root className="flex flex-col flex-auto items-center justify-center shrink-0 p-16 md:p-24 bg-gray-200">
			<motion.div
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				className="flex w-full max-w-400 md:max-w-2xl rounded-md shadow-2xl overflow-hidden"
			>
				<Card
					className="Login-leftSection pt-80 flex flex-col w-full max-w-400 items-center shadow-0 h-575"
					square
				>
					<CardContent className="flex-1 flex flex-col w-full h-full max-w-320">
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className="flex items-center justify-center mb-24">
								{isSubmitting && <CircularProgress size="6.5rem" />}
								{isSubmitted && isSubmitSuccessful && !isSubmitting && (
									<Icon className="text-128" color="primary">
										check
									</Icon>
								)}
								{isSubmitted && !isSubmitSuccessful && !isSubmitting && (
									<Icon className="text-128" color="primary">
										error_outlined
									</Icon>
								)}
							</div>
						</motion.div>
						{isSubmitted && isSubmitSuccessful && !isSubmitting && (
							<>
								<Typography variant="h5" className="text-center mb-16">
									En mail er blevet sendt
								</Typography>
								<Typography className="text-center mb-16 w-full" color="textSecondary">
									Følg linket i mailen for at nulstille din adgangskode.
								</Typography>
								<Button
									variant="contained"
									color="primary"
									className="w-full mx-auto mt-16 py-10 rounded-sm bg-loppe-blue-default hover:bg-loppe-blue-default"
									onClick={() => navigate('/login')}
								>
									Gå til log ind
								</Button>
							</>
						)}
						{isSubmitted && !isSubmitSuccessful && !isSubmitting && (
							<>
								<Typography variant="h5" className="text-center mb-16">
									Beklager
								</Typography>
								<Typography className="text-center mb-16 w-full" color="textSecondary">
									Der kunne ikke sendes en mail, prøv igen senere.
								</Typography>
							</>
						)}
						{isSubmitting && (
							<Typography variant="h5" className="text-center mb-16">
								Sender mail...
							</Typography>
						)}
						{!isSubmitted && !isSubmitting && (
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="flex-1">
									<Typography variant="h5" className="font-bold mb-32">
										Glemt adgangskode?
									</Typography>
									<TextFieldForm
										control={control}
										className="mb-16 w-full"
										type="text"
										name="email"
										label="Email"
										variant="outlined"
										required
										validations={{
											minLength: {
												value: 6,
												message: 'Adgangskoden skal være mindst 6 tegn',
											},
										}}
										fullWidth
										hideHelperText
									/>
									<div>
										<button
											className="font-bold mb-32 underline text-left inline-block"
											onClick={handleClickOnLogin}
											type={'button'}
										>
											Log ind i stedet
										</button>
									</div>
								</div>

								<Button
									variant="contained"
									className="w-full mx-auto py-10 mt-16 rounded-sm bg-loppe-blue-default hover:bg-loppe-blue-default"
									aria-label="FORGOT"
									type={'submit'}
								>
									Nulstil adgangskode
								</Button>
							</form>
						)}
					</CardContent>
				</Card>

				<div className="Login-rightSection hidden md:flex flex-1 items-center justify-center">
					<div className="w-10/12 mx-auto">
						<motion.div
							initial={{ opacity: 0, y: 40 }}
							animate={{
								opacity: 1,
								y: 0,
								transition: { delay: 0.2 },
							}}
						>
							<Typography variant="h3" color="inherit" className="font-semibold leading-tight">
								Loppemodul
							</Typography>
						</motion.div>

						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
							<Typography variant={'h6'} color="inherit" className="mt-32">
								Sælg dine brugte ting, og hold et overblik over dine salg - direkte fra din telefon
								eller computer.
							</Typography>
						</motion.div>
					</div>
				</div>
			</motion.div>
		</Root>
	);
}

export default ForgotPassword;
