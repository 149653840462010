import ForgotPassword from '@ameroservices-platform/loppe-boothownerui/app/main/forgot-password/ForgotPassword';

const ForgotPasswordConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	routes: [
		{
			path: 'forgot-password',
			element: <ForgotPassword />,
		},
	],
};

export default ForgotPasswordConfig;
