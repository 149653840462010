import { useEffect, useMemo, useState } from 'react';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
} from '@mui/material';
import { customerOrderLineListener } from '@ameroservices-platform/loppe-boothownerui/app/main/apps/sale/SaleFirestore';

function SaleOrderLineDetails({ orderLine, settlementOrderLine }) {
	const [settlementOrderLineData, setSettlementOrderLineData] = useState(null);

	useEffect(() => {
		const unsubFuncOrderLine = customerOrderLineListener(
			settlementOrderLine.customerUid,
			settlementOrderLine.orderUid,
			settlementOrderLine.id,
			(data) => setSettlementOrderLineData(data)
		);
		return () => {
			unsubFuncOrderLine();
		};
	}, [settlementOrderLine]);

	const settlementOrderLineForOrderLine = useMemo(() => {
		if (settlementOrderLineData) {
			return settlementOrderLineData.orderLines?.[orderLine.id];
		}
		return null;
	}, [settlementOrderLineData, orderLine]);

	return (
		<>
			{settlementOrderLineData && (
				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
						<Table size="small" aria-label="settlementOrderLineOrderLines">
							<TableBody>
								<div className="h-160 sm:h-80 my-12">
									<div
										className="flex flex-col sm:flex-row justify-between"
										key={settlementOrderLineForOrderLine.id}
									>
										<div>
											<div className="font-bold"># {orderLine.productNumber || orderLine.ameroServicesProductNumber}</div>
											<div className="truncate">{orderLine.name}</div>
											<div className="truncate">{orderLine.qty} stk</div>
											<div className="truncate">
												Stk pris: {moneyFormatter.format(orderLine.unitPrice / 100.0)}
											</div>
										</div>
										<div>
											<div className="truncate ">
												Rabat:{' '}
												{settlementOrderLineForOrderLine.discount
													? moneyFormatter.format(
														settlementOrderLineForOrderLine.discount / 100.0
													  )
													: '-'}
											</div>
											<div className="truncate">
												Total:{' '}
												{moneyFormatter.format(settlementOrderLineForOrderLine.linePrice / 100.0)}
											</div>
											<div className="truncate">
												Kommission: {`${settlementOrderLineForOrderLine.commissionRate / 100} %`}
											</div>
											<div className="truncate ">
												Standholder andel:{' '}
												{moneyFormatter.format(
													settlementOrderLineForOrderLine.calculations.accountShare / 100.0
												)}
											</div>
										</div>
									</div>
								</div>
							</TableBody>
						</Table>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}

export default SaleOrderLineDetails;
