import firebaseService from '@ameroservices-platform/shared/services/firebase';

export const customerOrderLineListener = (customerUid, orderUid, orderLineUid, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.doc(orderUid)
		.collection('orderLines')
		.doc(orderLineUid)
		.onSnapshot((q) => {
			callback({ ...q.data(), id: q.id });
		});
};
